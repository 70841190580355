import * as htmlToImage from "html-to-image";

import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { ITotalCostQualityDashboardViewModel, createTotalCostQualityDashboardViewModel } from "../../entities/TotalCostQualityDashboard";
import { faCalendar, faDownload } from "@fortawesome/free-solid-svg-icons";

import { AuthContext } from "../../helpers/Contexts";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Component } from "react";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IDeparmentDDL } from "../../entities/Departments";
import Select from "react-select";
import download from "downloadjs";
import { getData } from "../../helpers/Data";
import moment from "moment";

interface IProps {}

interface IState {
  filterItem: string;
  fromDate?: Date;
  graphType: string;
  departmentId: string;
  statistics?: ITotalCostQualityDashboardViewModel;
  toDate?: Date;
  departments: IDeparmentDDL[];
}

export default class QualityCostDashboard extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      filterItem: "2",
      fromDate: moment().startOf("year").toDate(),
      graphType: "jobCard",
      departments: [],
      departmentId: "",
      statistics: createTotalCostQualityDashboardViewModel(),
      toDate: moment().endOf("day").toDate(),
    };
  }

  static contextType = AuthContext;

  componentDidMount = () => {
    this.refresh();
  };

  refresh = async () => {
    await this.refreshDepartments();
  };

  refreshDepartments = () => {
    getData("departments/GetDepartmentDDL", { companyId: this.context.selectedCompany?.id }).then((data) => {
      this.setState({ departments: data, departmentId: this.state.departmentId !== "" ? this.state.departmentId : data[0].value });
      this.getChartData();
    });
  };

  getChartData = () => {
    let url = "reports/qualityCostDashboard";

    getData(url, {
      companyId: this.context.selectedCompany?.id,
      departmentId: this.state.departmentId,
      from: this.state.fromDate !== undefined ? moment(this.state.fromDate).format("DD MMM YYYY") : "",
      to: this.state.toDate !== undefined ? moment(this.state.toDate).format("DD MMM YYYY hh:mm") : "",
    }).then((data) => {
      this.setState({ statistics: data });
    });
  };

  setFilterItem = (item: any) => {
    let newFilterItem: string = item.value;

    let newFromDate = undefined;
    let newToDate = undefined;

    if (newFilterItem === "1") {
      newFromDate = moment().startOf("month").toDate();
      newToDate = moment().endOf("day").toDate();
    } else if (newFilterItem === "2") {
      newFromDate = moment().startOf("year").toDate();
      newToDate = moment().endOf("day").toDate();
    } else if (newFilterItem === "3") {
      newFromDate = moment().startOf("month").subtract(6, "months").toDate();
      newToDate = moment().endOf("day").toDate();
    }

    this.setState({ filterItem: newFilterItem, fromDate: newFromDate, toDate: newToDate }, () => this.refresh());
  };

  setGraphTypeFilter = (items: any) => {
    let newGraphType: string = "";

    items.forEach((element: any) => {
      newGraphType = element.value;
    });

    this.setState({ graphType: newGraphType }, () => this.refresh());
  };

  saveChartToPng = async (chartId: string, name: string) => {
    const canvas = document.getElementById(chartId) as HTMLCanvasElement;
    htmlToImage
      .toPng(canvas)
      .then((dataUrl) => {
        download(dataUrl, `${name}.png`);
      })
      .catch((err) => {
        console.error("Oops, something went wrong!", err);
      });
  };

  render() {
    const selectOptions = [
      { value: "1", label: "Month to Date" },
      { value: "2", label: "Year to Date" },
      { value: "3", label: "Custom" },
    ];

    let graph;
    if (this.state.departmentId !== "") {
      const totalDeparmetnCostChartOptions = {
        scales: {
          y: {
            beginAtZero: true,
            max: this.state.statistics?.maxValue === 0 ? undefined : this.state.statistics?.maxValue,
          },
        },
        responsive: true,
        plugins: {
          legend: {
            display: false,
            position: "bottom",
          },
          title: {
            display: true,
            text: "Total Cost Per Department",
            font: {
              size: 24,
            },
          },
          subtitle: {
            display: true,
            text: this.state.departmentId ? this.state.departments.filter((option: any) => option.value === this.state.departmentId)[0].label : "",
            font: {
              size: 20,
            },
            position: "bottom",
          },
          datalabels: {
            display: true,
            align: "center",
            borderRadius: 2,
            font: {
              size: 16,
            },
            color: "#222",
            backgroundColor: "#ffffff66",
            formatter: (value: any) => {
              return value.toLocaleString("en-ZA", {
                style: "currency",
                currency: "ZAR",
                minimumFractionDigits: 0, // Ensures no decimals
                maximumFractionDigits: 0, // Ensures no decimals
              });
            },
          },
        },
        spanGaps: true,
      };
      const totalCompanyCostChartOptions = {
        scales: {
          y: {
            beginAtZero: true,
            max: this.state.statistics?.maxValue === 0 ? undefined : this.state.statistics?.maxValue,
          },
        },
        responsive: true,
        plugins: {
          legend: {
            display: false,
            position: "bottom",
          },
          title: {
            display: true,
            text: "Total Costs For Company",
            font: {
              size: 24,
            },
          },
          subtitle: {
            display: true,
            text: "Total For Company",
            font: {
              size: 20,
            },
            position: "bottom",
          },
          datalabels: {
            display: true,
            align: "center",
            borderRadius: 2,
            font: {
              size: 16,
            },
            color: "#222",
            backgroundColor: "#ffffff66",
            formatter: (value: any) => {
              return value.toLocaleString("en-ZA", {
                style: "currency",
                currency: "ZAR",
                minimumFractionDigits: 0, // Ensures no decimals
                maximumFractionDigits: 0, // Ensures no decimals
              });
            },
          },
        },
        spanGaps: true,
      };

      const monthTotalsCompanyCost = this.state.statistics?.totalsPerCompany?.monthTotals || [];
      const firstValueCompanyCost = monthTotalsCompanyCost.length > 0 ? monthTotalsCompanyCost[0] : 0;
      const lastValueCompanyCost = monthTotalsCompanyCost.length > 1 ? monthTotalsCompanyCost[monthTotalsCompanyCost.length - 1] : firstValueCompanyCost; // Handle case with only 1 value

      // Generate the data array for the trendline
      let trendlineDataCompanyCost = [];
      if (monthTotalsCompanyCost.length > 1) {
        // Create the trendline: start at first value, end at last value, fill nulls in between
        trendlineDataCompanyCost = [firstValueCompanyCost, ...Array(monthTotalsCompanyCost.length - 2).fill(null), lastValueCompanyCost];
      } else {
        // If there's only one data point, just use it for the trendline
        trendlineDataCompanyCost = [firstValueCompanyCost];
      }

      const totalCompanyCostData = {
        labels: this.state.statistics?.totalsPerCompany?.labels,
        datasets: [
          {
            type: "line",
            data: trendlineDataCompanyCost,
            borderColor: "rgba(255, 99, 132, 1)",
            borderWidth: 2,
            fill: false, // Ensure the line isn't filled under
            pointRadius: 0, // Hide the points if you don't want them visible
            tension: 0.4, // Adds a curve to the line (0 = straight lines)
            tooltip: { enabled: false },
            // Hide data labels for the trendline
            datalabels: {
              display: false, // Disable labels for the line
            },
          },
          {
            label: "R",
            data: monthTotalsCompanyCost,
            backgroundColor: this.state.statistics?.totalsPerCompany?.colour,
            borderWidth: 0,
          },
        ],
      };

      const monthTotalsPerDepartment = this.state.statistics?.totalsPerDepartment?.monthTotals || [];
      const firstValuePerDepartment = monthTotalsPerDepartment.length > 0 ? monthTotalsPerDepartment[0] : 0;
      const lastValuePerDepartment =
        monthTotalsPerDepartment.length > 1 ? monthTotalsPerDepartment[monthTotalsPerDepartment.length - 1] : firstValuePerDepartment; // Handle case with only 1 value

      // Generate the data array for the trendline
      let trendlineDataPerDepartment = [];
      if (monthTotalsPerDepartment.length > 1) {
        // Create the trendline: start at first value, end at last value, fill nulls in between
        trendlineDataPerDepartment = [firstValuePerDepartment, ...Array(monthTotalsPerDepartment.length - 2).fill(null), lastValuePerDepartment];
      } else {
        // If there's only one data point, just use it for the trendline
        trendlineDataPerDepartment = [firstValuePerDepartment];
      }

      const totalPerDepartmentData = {
        labels: this.state.statistics?.totalsPerDepartment?.labels,
        datasets: [
          {
            type: "line",
            data: trendlineDataPerDepartment,
            borderColor: "rgba(255, 99, 132, 1)",
            borderWidth: 2,
            fill: false, // Ensure the line isn't filled under
            pointRadius: 0, // Hide the points if you don't want them visible
            tension: 0.4, // Adds a curve to the line (0 = straight lines)
            tooltip: { enabled: false },
            // Hide data labels for the trendline
            datalabels: {
              display: false, // Disable labels for the line
            },
          },
          {
            label: "R",
            data: this.state.statistics?.totalsPerDepartment?.monthTotals,
            backgroundColor: this.state.statistics?.totalsPerDepartment?.colour,
            borderWidth: 0,
          },
        ],
      };

      graph = (
        <Row>
          <Col className="text-center">
            <Button variant={"success"} size="sm" title="Download Chart" onClick={() => this.saveChartToPng("7", `Total Breakdowns per Machine`)}>
              <FontAwesomeIcon icon={faDownload} />
            </Button>
            <div id="7" style={{ backgroundColor: "white" }}>
              <Bar data={totalPerDepartmentData} height={200} plugins={[ChartDataLabels]} options={totalDeparmetnCostChartOptions} />
            </div>
          </Col>
          <Col className="text-center">
            <Button variant={"success"} size="sm" title="Download Chart" onClick={() => this.saveChartToPng("8", `Total Breakdowns per Operational Unit`)}>
              <FontAwesomeIcon icon={faDownload} />
            </Button>
            <div id="8" style={{ backgroundColor: "white" }}>
              <Bar data={totalCompanyCostData} height={200} plugins={[ChartDataLabels]} options={totalCompanyCostChartOptions} />
            </div>
          </Col>
        </Row>
      );
    }

    let row;
    if (Number(this.state.filterItem) < 3) {
      row = (
        <Row className="align-items-center">
          <Col>Quality Cost Dashboard</Col>
          <Col sm={3} md={3} lg={3}>
            <Select
              options={this.state.departments}
              onChange={(cat: any) => {
                this.setState({ departmentId: cat.value, statistics: createTotalCostQualityDashboardViewModel() }, () => this.refresh());
              }}
              value={this.state.departmentId ? this.state.departments.filter((option: any) => option.value === this.state.departmentId) : null}
            />
          </Col>
          <Col sm={3} md={3} lg={2}>
            <Select options={selectOptions} defaultValue={selectOptions[1]} onChange={(e) => this.setFilterItem(e)} placeholder="All" />
          </Col>
        </Row>
      );
    } else if (this.state.filterItem === "3") {
      row = (
        <Row className="align-items-center">
          <Col>Quality Cost Dashboard</Col>

          <Col sm={3} md={3} lg={3}>
            <Select
              options={this.state.departments}
              onChange={(cat: any) => {
                this.setState({ departmentId: cat.value, statistics: createTotalCostQualityDashboardViewModel() }, () => this.refresh());
              }}
              value={this.state.departmentId ? this.state.departments.filter((option: any) => option.value === this.state.departmentId) : null}
            />
          </Col>
          <Col sm={3} md={3} lg={2}>
            <Select options={selectOptions} onChange={(e) => this.setFilterItem(e)} />
          </Col>
          <Col sm={2} md={2} lg={2}>
            <div className="customDatePickerWidth">
              <DatePicker
                customInput={
                  <InputGroup className="">
                    <Form.Control placeholder="Date" value={moment(this.state.fromDate).format("DD/MM/YYYY")} />
                    <InputGroup.Append>
                      <InputGroup.Text id="basic-addon2">
                        <FontAwesomeIcon icon={faCalendar} />
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                }
                onChange={(date: Date) => this.setState({ fromDate: date }, () => this.refresh())}
              />
            </div>
          </Col>
          <Col sm={2} md={2} lg={2}>
            <div className="customDatePickerWidth">
              <DatePicker
                customInput={
                  <InputGroup className="">
                    <Form.Control placeholder="Date" value={moment(this.state.toDate).format("DD/MM/YYYY")} />
                    <InputGroup.Append>
                      <InputGroup.Text id="basic-addon2">
                        <FontAwesomeIcon icon={faCalendar} />
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                }
                onChange={(date: Date) => this.setState({ toDate: date }, () => this.refresh())}
              />
            </div>
          </Col>
        </Row>
      );
    }
    return (
      <div>
        <Card style={{ width: "100%" }}>
          <Card.Header>
            <Card.Title>{row}</Card.Title>
          </Card.Header>
          <Card.Body>{graph}</Card.Body>
        </Card>
      </div>
    );
  }
}

import {
  ArtisanHandOverPopover,
  ArtisanPopover,
  ForemanPopover,
  InitiatorPopover,
  MachineRegisterPopover,
  MaintenancePopover,
  OperationalUnitPopover,
  SourcePopover,
} from "../../maintenance/maintenancePopovers";
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Col,
  Dropdown,
  Form,
  InputGroup,
  Modal,
  ModalFooter,
  OverlayTrigger,
  Row,
  Spinner,
  Table,
  Toast,
  Tooltip,
} from "react-bootstrap";
import { EJobCardStatus, EStatusColours } from "../../helpers/enums/enums";
import { faCalendar, faFileArchive, faFileExcel, faFilter, faListAlt, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { getData, getReport, postData } from "../../helpers/Data";

import { AuthContext } from "../../helpers/Contexts";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IArtisanHandoverSlim } from "../../entities/ArtisanHandover";
import { ICompanyName } from "../../entities/Company";
import { IJobCard } from "../../entities/JobCard";
import { IJobCardNotification } from "../../entities/JobCardNotification";
import { IMachineRegisterDDL } from "../../entities/MachineRegister";
import { IMachineSectionDropDownList } from "../../entities/MachineSections";
import { IMaintenanceTypeList } from "../../entities/MaintenanceTypes";
import { IOperationalUnitList } from "../../entities/OperationalUnits";
import JobCardDetail from "../../modals/jobCardDialog";
import MaintenanceStats from "../../maintenance/maintenanceStats";
import PlannedJobCardDetail from "../../modals/plannedJobCardDialog";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import Select from "react-select";
import { maintenanceColumns } from "../../maintenance/maintenanceColumns";
import moment from "moment";

interface IProps extends RouteComponentProps {}

interface IState {
  artisanHandovers: IArtisanHandoverSlim[];
  cancelReason?: string;
  columns: any[];
  company: ICompanyName;
  filterItems: number[];
  fromDate?: Date;
  jobCards: [];
  lastOpenedJobCards: ILastOpenedJobCard[];
  loading: boolean;
  loadingJobCards: boolean;
  loadingNotifications: boolean;
  machineRegisterId: string | null;
  machineRegisterIds: string[];
  machineRegisters: IMachineRegisterDDL[];
  machineSectionId: string | null;
  machineSectionIds: string[];
  maintenanceTypes: IMaintenanceTypeList[];
  notifications: IJobCardNotification[];
  operationalUnits: IOperationalUnitList[];
  selectedJobCard?: IJobCard;
  selectedMachineSections: IMachineSectionDropDownList[];
  showCancelDialog: boolean;
  showJobCardDialog: boolean;
  showPlannedJobCardDialog: boolean;
  sortBy?: string | null;
  text: string;
  toDate?: Date;
}

interface ILastOpenedJobCard {
  jobCardId: string;
  jobCardNumber: string;
  dateOpened: Date;
}

const sortByValues = [
  { value: "newest", label: "Newest" },
  { value: "oldest", label: "Oldest" },
];

export default class MaintenancePage extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      text: "",
      filterItems: [],
      showJobCardDialog: false,
      showPlannedJobCardDialog: false,
      jobCards: [],
      company: { companyPrefix: "", jobCardPrefix: "" },
      artisanHandovers: [],
      maintenanceTypes: [],
      machineRegisters: [],
      machineRegisterId: null,
      machineRegisterIds: [],
      machineSectionIds: [],
      machineSectionId: null,
      selectedMachineSections: [],
      notifications: [],
      loading: false,
      loadingJobCards: true,
      loadingNotifications: true,
      operationalUnits: [],
      fromDate: moment().startOf("month").subtract(1, "months").toDate(),
      toDate: moment().toDate(),
      showCancelDialog: false,
      columns: localStorage.getItem("columnsMaintenance")
        ? JSON.parse(localStorage.getItem("columnsMaintenance") ?? "")
        : [
            { value: "Initiator", label: "Initiator", filter: "" },
            { value: "Maintenance Foreman", label: "Maintenance Admin", filter: "" },
            { value: "Artisan", label: "Artisan", filter: "" },
            { value: "Title", label: "Title", filter: "" },
            { value: "TargetCloseDate", label: "Target Close Date", filter: "" },
          ],
      lastOpenedJobCards: localStorage.getItem("lastOpenedJobCards") ? JSON.parse(localStorage.getItem("lastOpenedJobCards") ?? "") : [],
      sortBy: localStorage.getItem("sortBy") ? localStorage.getItem("sortBy") : "oldest",
    };
  }
  static contextType = AuthContext;

  componentDidMount = () => {
    this.refreshJobCardList();
    this.refreshNotificationList();
    this.getCompany();
    this.getArtisanHandovers();
    this.getMaintenanceTypes();
    this.getOperationalUnits();
    this.refreshMachineRegisters();
    var id = new URLSearchParams(this.props.location.search).get("id");
    var maintenance = new URLSearchParams(this.props.location.search).get("maintenance");
    if (id != null && maintenance && maintenance === "true") {
      this.openJobCardFromUrl(id);
    }
  };

  refreshJobCardList = () => {
    this.setState({ loadingJobCards: true });
    getData("jobCards", {
      text: this.state.text,
      companyId: this.context.selectedCompany?.id,
      statusIDs: this.state.filterItems,
      machineRegisterIds: this.state.machineRegisterIds,
      machineSectionIds: this.state.machineSectionIds,
      toDate: moment(this.state.toDate).format("DD/MM/YYYY"),
      fromDate: moment(this.state.fromDate).format("DD/MM/YYYY"),
      // source: "System",
      sortBy: this.state.sortBy,
    }).then((data) => {
      this.setState({ jobCards: data, loadingJobCards: false });
    });
  };

  refreshMachineRegisters = () => {
    getData("machineRegisters/GetMachineRegisterDDL", { companyId: this.context.selectedCompany?.id }).then((data) => {
      this.setState({ machineRegisters: data });
    });
  };

  getCompany = () => {
    getData("companies/" + this.context.selectedCompany.id).then((data) => {
      this.setState({ company: data });
    });
  };

  getMaintenanceTypes = () => {
    getData("MaintenanceTypes", { companyId: this.context.selectedCompany?.id }).then((data) => {
      this.setState({ maintenanceTypes: data });
    });
  };

  getArtisanHandovers = () => {
    getData("artisanHandovers/byCompany", { companyId: this.context.selectedCompany?.id }).then((data) => {
      this.setState({ artisanHandovers: data });
    });
  };

  getOperationalUnits = () => {
    getData("operationalUnits", { companyId: this.context.selectedCompany?.id }).then((data) => {
      this.setState({ operationalUnits: data });
    });
  };

  refreshNotificationList = () => {
    getData("jobCardNotifications/list").then((data) => {
      this.setState({ notifications: data, loadingNotifications: false });
    });
  };

  newPlannedJobCardButtonClick = () => {
    this.setState({
      showPlannedJobCardDialog: true,
    });
  };

  closePlannedJobCardDialog = () => {
    this.refreshJobCardList();
    this.setState({ showPlannedJobCardDialog: false });
  };

  newJobCardButtonClick = () => {
    this.setState({
      showJobCardDialog: true,
      selectedJobCard: {
        allToolsAndSparesReturned: false,
        artisanHandovers: [],
        comments: [],
        companyId: "",
        costs: [],
        createdBy: "",
        createdOn: new Date(),
        date: new Date(),
        description: "",
        files: [],
        jobCardTools: [],
        machineCleaned: false,
        machineRunning: false,
        maintenanceForemanId: "",
        maintenanceForemanText: "",
        maintenanceOrRepairsDone: false,
        maintenanceTypeId: "",
        maintenanceTypeText: "",
        name: "",
        noUnaccountedToolsAndSpares: false,
        planned: false,
        operationalUnitId: "",
        operationalUnitText: "",
        status: "",
        statusId: 1,
      },
    });
  };

  closeJobCardDialog = () => {
    this.refreshJobCardList();
    this.setState({
      showJobCardDialog: false,
      selectedJobCard: {
        allToolsAndSparesReturned: false,
        artisanHandovers: [],
        comments: [],
        companyId: "",
        costs: [],
        createdBy: "",
        createdOn: new Date(),
        date: new Date(),
        description: "",
        files: [],
        jobCardTools: [],
        machineCleaned: false,
        machineRunning: false,
        maintenanceForemanId: "",
        maintenanceForemanText: "",
        maintenanceOrRepairsDone: false,
        maintenanceTypeId: "",
        maintenanceTypeText: "",
        name: "",
        noUnaccountedToolsAndSpares: false,
        operationalUnitId: "",
        operationalUnitText: "",
        planned: false,
        status: "",
        statusId: 1,
      },
    });
  };

  addOrRemoveLastJobCardsOpened = async (jobCardIdId: string) => {
    const jobCard = await getData("jobCards/" + jobCardIdId);

    let lastOpenedJobCards = this.state.lastOpenedJobCards;

    if (lastOpenedJobCards.length > 5) lastOpenedJobCards.pop();

    if (lastOpenedJobCards.find((f) => f.jobCardId === jobCard.id)) {
      const index = lastOpenedJobCards.findIndex((f) => f.jobCardId === jobCard.id);
      lastOpenedJobCards[index].dateOpened = new Date();
    } else {
      lastOpenedJobCards.push({
        jobCardId: jobCard.id as string,
        dateOpened: new Date(),
        jobCardNumber: `${
          this.state.company.jobCardPrefix !== null && this.state.company.jobCardPrefix !== "" ? this.state.company.jobCardPrefix : "Job Card"
        } ${jobCard.jobCardNumber?.toString().padStart(6, "0")}`,
      });
    }

    lastOpenedJobCards.sort((a: any, b: any) => new Date(a.dateOpened).getTime() - new Date(b.dateOpened).getTime());
    lastOpenedJobCards.reverse();

    localStorage.setItem("lastOpenedJobCards", JSON.stringify(lastOpenedJobCards));
    this.setState({ lastOpenedJobCards: lastOpenedJobCards });
  };

  openJobCard = (jobCard: IJobCard) => {
    this.addOrRemoveLastJobCardsOpened(jobCard.id as string);
    this.setState({ selectedJobCard: jobCard, showJobCardDialog: true });
  };

  openJobCardFromUrl = (jobCardId: string) => {
    this.addOrRemoveLastJobCardsOpened(jobCardId);
    this.setState({
      showJobCardDialog: true,
      selectedJobCard: {
        allToolsAndSparesReturned: false,
        artisanHandovers: [],
        comments: [],
        companyId: "",
        costs: [],
        createdBy: "",
        createdOn: new Date(),
        date: new Date(),
        description: "",
        files: [],
        id: jobCardId,
        jobCardTools: [],
        machineCleaned: false,
        machineRunning: false,
        maintenanceForemanId: "",
        maintenanceForemanText: "",
        maintenanceOrRepairsDone: false,
        maintenanceTypeId: "",
        maintenanceTypeText: "",
        name: "",
        noUnaccountedToolsAndSpares: false,
        planned: false,
        operationalUnitId: "",
        operationalUnitText: "",
        status: "",
        statusId: 1,
      },
    });
  };

  openJobCardNotification = (jobCardId: string, notificationId: string) => {
    this.addOrRemoveLastJobCardsOpened(jobCardId);
    this.setState(
      {
        showJobCardDialog: true,
        selectedJobCard: {
          allToolsAndSparesReturned: false,
          artisanHandovers: [],
          comments: [],
          companyId: "",
          costs: [],
          createdBy: "",
          createdOn: new Date(),
          date: new Date(),
          description: "",
          files: [],
          id: jobCardId,
          jobCardTools: [],
          machineCleaned: false,
          machineRunning: false,
          maintenanceForemanId: "",
          maintenanceForemanText: "",
          maintenanceOrRepairsDone: false,
          maintenanceTypeId: "",
          maintenanceTypeText: "",
          name: "",
          noUnaccountedToolsAndSpares: false,
          planned: false,
          operationalUnitId: "",
          operationalUnitText: "",
          status: "",
          statusId: 1,
        },
      },
      () => this.readNotification(notificationId)
    );
  };

  readNotification = (id: string) => {
    postData("jobCardNotifications/read", { id: id }).then((res) => {
      this.refreshNotificationList();
    });
  };

  setFilterItems = (items: any) => {
    let filterItems: any[] = [];
    items.forEach((element: any) => {
      filterItems.push(element.value);
    });
    this.setState({ filterItems }, () => this.refreshJobCardList());
  };

  setSortBy = (item: any) => {
    localStorage.setItem("sortBy", item.value);
    this.setState({ sortBy: item.value }, () => this.refreshJobCardList());
  };

  setMachineRegisterItems = (items: any) => {
    let machineRegisterIds: any[] = [];
    items.forEach((element: any) => {
      machineRegisterIds.push(element.value);
    });
    this.setState({ machineRegisterIds }, () => this.refreshJobCardList());
  };

  setMachineSectionItems = (items: any) => {
    debugger;
    let machineSectionIds: any[] = [];
    items.forEach((element: any) => {
      machineSectionIds.push(element.value);
    });
    this.setState({ machineSectionIds }, () => this.refreshJobCardList());
  };

  setColumnItems = (items: any) => {
    let columns: any[] = [];
    items.forEach((element: any) => {
      columns.push(element);
    });
    this.setState({ columns }, () => localStorage.setItem("columnsMaintenance", JSON.stringify(columns)));
  };

  setSelectedFilterField = (value: any, field: keyof any, column: string) => {
    let cols = this.state.columns;
    let col = cols.find((f) => f.value === column);
    cols = cols.filter((f) => f !== col);
    col = this.prop(col, field, value.target.value);
    cols.push(col);
    this.setState({ columns: cols }, () => {
      localStorage.setItem("columnsMaintenance", JSON.stringify(cols));
    });
  };

  prop<T, K extends keyof T>(obj: T, key: K, newVal: any) {
    obj[key] = newVal;
    return obj;
  }

  downloadJobCardList = () => {
    getReport(
      "jobCards/download",
      {
        companyId: this.context.selectedCompany?.id,
        statusIDs: this.state.filterItems,
        toDate: moment(this.state.toDate).format("DD/MM/YYYY"),
        fromDate: moment(this.state.fromDate).format("DD/MM/YYYY"),
      },
      `${this.state.company.jobCardPrefix && this.state.company.jobCardPrefix !== "" ? this.state.company.jobCardPrefix : "Job Card"} Register`
    ).then((data) => {});
  };

  downloadJobCardDetail = (id: string, name: string) => {
    getReport(
      "jobCards/jobCardReport",
      { id: id },
      `${this.state.company?.jobCardPrefix && this.state.company.jobCardPrefix !== "" ? this.state.company.jobCardPrefix : "Job Card"}${name}`
    ).then((data) => {});
  };

  cancelJobCard = (jobCard: IJobCard) => {
    this.setState({ selectedJobCard: jobCard, showCancelDialog: true });
  };

  onCancel = () => {
    this.setState({ loading: true });
    postData("jobCards/cancel", { JobCardId: this.state.selectedJobCard?.id, Reasons: this.state.cancelReason }).then((res) => {
      this.refreshJobCardList();
      this.setState({
        showCancelDialog: false,
        selectedJobCard: {
          allToolsAndSparesReturned: false,
          artisanHandovers: [],
          comments: [],
          companyId: "",
          costs: [],
          createdBy: "",
          createdOn: new Date(),
          date: new Date(),
          description: "",
          files: [],
          jobCardTools: [],
          machineCleaned: false,
          machineRunning: false,
          maintenanceForemanId: "",
          maintenanceForemanText: "",
          maintenanceOrRepairsDone: false,
          maintenanceTypeId: "",
          maintenanceTypeText: "",
          name: "",
          noUnaccountedToolsAndSpares: false,
          planned: false,
          operationalUnitId: "",
          operationalUnitText: "",
          status: "",
          statusId: 1,
        },
        cancelReason: undefined,
        loading: false,
      });
    });
  };

  onCloseCancelDialog = () => {
    this.setState({
      showCancelDialog: false,
      selectedJobCard: {
        allToolsAndSparesReturned: false,
        artisanHandovers: [],
        comments: [],
        companyId: "",
        costs: [],
        createdBy: "",
        createdOn: new Date(),
        date: new Date(),
        description: "",
        files: [],
        jobCardTools: [],
        machineCleaned: false,
        machineRunning: false,
        maintenanceForemanId: "",
        maintenanceForemanText: "",
        maintenanceOrRepairsDone: false,
        maintenanceTypeId: "",
        maintenanceTypeText: "",
        name: "",
        noUnaccountedToolsAndSpares: false,
        planned: false,
        operationalUnitId: "",
        operationalUnitText: "",
        status: "",
        statusId: 1,
      },
      cancelReason: undefined,
    });
  };

  setMachineSections = (items: any[]) => {
    let machineSections: any[] = [];

    items.forEach((element: any) => {
      (element.machineSections as any[]).forEach((section: any) => {
        if (machineSections.some((s) => s.value === section.value)) {
        } else {
          machineSections.push(section);
        }
      });
    });

    this.setState({ selectedMachineSections: machineSections });
  };

  render() {
    let isSA = this.context.User.roles.includes("SuperAdministrators");
    let isAdmin = this.context.User.roles.includes("Administrators");
    let isMaintenance = this.context.User.roles.includes("MaintenanceUsers");

    const options = [
      { value: "1", label: "Initiated" },
      { value: "2", label: "Assigned" },
      { value: "3", label: "Pending Maintenance Admin Sign Off" },
      { value: "4", label: "Pending Maintenance Manager Admin sign Off" },
      { value: "5", label: "Closed" },
      { value: "6", label: "Cancelled" },
    ];

    let initiators = Array.from(new Set(this.state.jobCards.map((jobCard: IJobCard) => jobCard.createdByText)));
    let maintenanceForemans = Array.from(new Set(this.state.jobCards.map((jobCard: IJobCard) => jobCard.maintenanceForemanText)));
    let operationalUnits = Array.from(new Set(this.state.operationalUnits.map((opUnit: IOperationalUnitList) => opUnit.name)));
    let maintenanceTypes = Array.from(new Set(this.state.maintenanceTypes.map((main: IMaintenanceTypeList) => main.name)));
    let artisans = Array.from(new Set(this.state.jobCards.map((jobCard: IJobCard) => jobCard.artisanText)));
    let machineRegisters = Array.from(new Set(this.state.machineRegisters.map((machine: IMachineRegisterDDL) => machine.label)));
    let artisanHandovers = Array.from(new Set(this.state.artisanHandovers.map((handover) => handover.artisanName)));
    let sources = Array.from(new Set(this.state.jobCards.map((jobCard: IJobCard) => jobCard.source)));

    let jobsCards: any[] = this.state.jobCards;

    if (this.state.columns.find((f) => f.value === "Initiator") && this.state.columns.find((f) => f.value === "Initiator").filter !== "") {
      jobsCards = jobsCards.filter((f: IJobCard) => f.createdByText?.trim() === this.state.columns.find((f) => f.value === "Initiator").filter);
    }
    if (this.state.columns.find((f) => f.value === "Maintenance Foreman") && this.state.columns.find((f) => f.value === "Maintenance Foreman").filter !== "") {
      jobsCards = jobsCards.filter(
        (f: IJobCard) => f.maintenanceForemanText?.trim() === this.state.columns.find((f) => f.value === "Maintenance Foreman").filter
      );
    }
    if (this.state.columns.find((f) => f.value === "Operational Unit") && this.state.columns.find((f) => f.value === "Operational Unit").filter !== "") {
      jobsCards = jobsCards.filter((f: IJobCard) => f.operationalUnitText?.trim() === this.state.columns.find((f) => f.value === "Operational Unit").filter);
    }
    if (this.state.columns.find((f) => f.value === "Maintenance Type") && this.state.columns.find((f) => f.value === "Maintenance Type").filter !== "") {
      jobsCards = jobsCards.filter((f: IJobCard) => f.maintenanceTypeText?.trim() === this.state.columns.find((f) => f.value === "Maintenance Type").filter);
    }
    if (this.state.columns.find((f) => f.value === "Artisan") && this.state.columns.find((f) => f.value === "Artisan").filter !== "") {
      jobsCards = jobsCards.filter((f: IJobCard) => f.artisanText?.trim() === this.state.columns.find((f) => f.value === "Artisan").filter);
    }
    if (this.state.columns.find((f) => f.value === "Artisan Handover") && this.state.columns.find((f) => f.value === "Artisan Handover").filter !== "") {
      jobsCards = jobsCards.filter((f: IJobCard) =>
        f.artisanHandovers?.some((s) => s.artisanName === this.state.columns.find((f) => f.value === "Artisan Handover").filter)
      );
    }
    if (this.state.columns.find((f) => f.value === "Machine Number") && this.state.columns.find((f) => f.value === "Machine Number").filter !== "") {
      jobsCards = jobsCards.filter((f: IJobCard) => f.machineNumber?.trim() === this.state.columns.find((f) => f.value === "Machine Number").filter);
    }
    if (this.state.columns)
      if (this.state.columns.find((f) => f.value === "Source") && this.state.columns.find((f) => f.value === "Source").filter !== "") {
        jobsCards = jobsCards.filter((f: IJobCard) => f.source?.trim() === this.state.columns.find((f) => f.value === "Source").filter);
      }

    return (
      <div>
        <JobCardDetail
          history={this.props.history}
          location={this.props.location}
          match={this.props.match}
          onClose={this.closeJobCardDialog}
          user={this.context.User}
          show={this.state.showJobCardDialog}
          jobCard={this.state.selectedJobCard}
        />
        <PlannedJobCardDetail
          history={this.props.history}
          location={this.props.location}
          match={this.props.match}
          onClose={this.closePlannedJobCardDialog}
          show={this.state.showPlannedJobCardDialog}
        />
        {/* <NavChecker type="maintenance" /> */}
        <Row>
          <Col lg={9}>
            <div className="d-lg-none">
              <Button variant="primary" onClick={() => this.newJobCardButtonClick()} block>
                Initiate New{" "}
                {this.state.company.jobCardPrefix !== "" && this.state.company.jobCardPrefix !== null ? this.state.company.jobCardPrefix : "Job Card"}
              </Button>
              {(this.context.selectedCompany.plannedMaintenance || isSA) && (isAdmin || isMaintenance) && (
                <Button variant="primary" onClick={() => this.newPlannedJobCardButtonClick()} block>
                  Create/View Planned{" "}
                  {this.state.company.jobCardPrefix !== "" && this.state.company.jobCardPrefix !== null ? this.state.company.jobCardPrefix : "Job Card"}s
                </Button>
              )}
              <br />
            </div>

            <Row>
              <Col lg={12}>
                <Card style={{ width: "100%" }}>
                  <Card.Header>
                    <Card.Title>
                      <Button className="float-right" variant="primary" onClick={() => this.downloadJobCardList()}>
                        <FontAwesomeIcon icon={faFileExcel} /> Download{" "}
                        {this.state.company.jobCardPrefix !== null && this.state.company.jobCardPrefix !== "" ? this.state.company.jobCardPrefix : "Job Card"}{" "}
                        Register
                      </Button>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Table borderless size="sm">
                      <tbody>
                        <tr>
                          <td style={{ width: "50px" }} className="text-right align-middle">
                            From
                          </td>
                          <td style={{ width: "200px" }}>
                            {" "}
                            <DatePicker
                              customInput={
                                <InputGroup className="">
                                  <Form.Control placeholder="Date" value={moment(this.state.fromDate).format("DD/MM/YYYY")} width="100%" />
                                  <InputGroup.Append>
                                    <InputGroup.Text id="basic-addon2">
                                      <FontAwesomeIcon icon={faCalendar} />
                                    </InputGroup.Text>
                                  </InputGroup.Append>
                                </InputGroup>
                              }
                              onChange={(date: Date) => this.setState({ fromDate: date }, () => this.refreshJobCardList())}
                            />
                          </td>
                          <td style={{ width: "50px" }} className="text-right align-middle">
                            To
                          </td>
                          <td style={{ width: "200px" }}>
                            <DatePicker
                              customInput={
                                <InputGroup className="">
                                  <Form.Control placeholder="Date" value={moment(this.state.toDate).format("DD/MM/YYYY")} width="100%" />
                                  <InputGroup.Append>
                                    <InputGroup.Text id="basic-addon2">
                                      <FontAwesomeIcon icon={faCalendar} />
                                    </InputGroup.Text>
                                  </InputGroup.Append>
                                </InputGroup>
                              }
                              onChange={(date: Date) => this.setState({ toDate: date }, () => this.refreshJobCardList())}
                            />
                          </td>
                          <td style={{ width: "150px" }}>
                            <Select
                              options={sortByValues}
                              value={sortByValues.find((f) => f.value === this.state.sortBy)}
                              onChange={(e: any) => this.setSortBy(e)}
                              placeholder="Order By"
                            />
                          </td>
                          <td style={{ width: "200px" }} className="d-none d-lg-table-cell">
                            {" "}
                            <Select options={options} isMulti onChange={(e) => this.setFilterItems(e)} placeholder="Filter Status" />
                          </td>
                          <td className="d-none d-lg-table-cell">
                            <InputGroup className="">
                              <Form.Control
                                placeholder="Search"
                                value={this.state.text}
                                width="100%"
                                onChange={(e) => this.setState({ text: e.target.value })}
                              />
                              <InputGroup.Append>
                                {this.state.text !== "" && (
                                  <Button
                                    variant="outline-secondary"
                                    style={{ padding: "0px 12px" }}
                                    onClick={() => this.setState({ text: "" }, () => this.refreshJobCardList())}
                                  >
                                    <FontAwesomeIcon icon={faTimes} />
                                  </Button>
                                )}
                                <Button variant="secondary" style={{ padding: "0px 12px" }} onClick={() => this.refreshJobCardList()}>
                                  <FontAwesomeIcon icon={faSearch} />
                                </Button>
                              </InputGroup.Append>
                            </InputGroup>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={4} className="d-lg-none">
                            <Select options={options} isMulti onChange={(e) => this.setFilterItems(e)} placeholder="Filter Status" />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={4} className="d-lg-none">
                            <InputGroup className="">
                              <Form.Control
                                placeholder="Search"
                                value={this.state.text}
                                width="100%"
                                onChange={(e) => this.setState({ text: e.target.value })}
                              />
                              <InputGroup.Append>
                                {this.state.text !== "" && (
                                  <Button
                                    variant="outline-secondary"
                                    style={{ padding: "0px 12px" }}
                                    onClick={() => this.setState({ text: "" }, () => this.refreshJobCardList())}
                                  >
                                    <FontAwesomeIcon icon={faTimes} />
                                  </Button>
                                )}
                                <Button variant="secondary" style={{ padding: "0px 12px" }} onClick={() => this.refreshJobCardList()}>
                                  <FontAwesomeIcon icon={faSearch} />
                                </Button>
                              </InputGroup.Append>
                            </InputGroup>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "50px" }} className="text-right align-middle">
                            Columns
                          </td>
                          <td colSpan={7}>
                            <Select
                              options={maintenanceColumns}
                              value={this.state.columns}
                              isMulti
                              onChange={(e) => this.setColumnItems(e)}
                              placeholder="Set Columns"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </Table>

                    <Row>
                      <Col xs={12} sm={12} md={12} lg={6}>
                        <Form.Group controlId="formHorizontalEmail">
                          <Select
                            as="Input"
                            options={this.state.machineRegisters}
                            isMulti
                            onChange={(cat: any) => {
                              this.setMachineRegisterItems(cat);
                              this.setMachineSections(cat);
                            }}
                            placeholder="Select Machine Number"
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={6}>
                        <Form.Group>
                          <Select
                            isMulti
                            as="Input"
                            placeholder="Select Machine Section"
                            options={this.state.selectedMachineSections}
                            onChange={(val: any) => this.setMachineSectionItems(val)}
                          />
                        </Form.Group>
                      </Col>
                      <Col className="text-right">
                        {/* TODO: Create maintenance help modal */}
                        {/* <HelpModal /> */}
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <br />
                        <Table striped hover size="sm" bordered responsive className="sticky-header-table">
                          <div style={{ maxHeight: "50vh" }}>
                            <thead className="sticky-header">
                              <tr>
                                <th>#</th>
                                <th className="status-column">Status</th>
                                <th>Initiation Date</th>
                                <th>Planned/Breakdown Date</th>
                                {this.state.columns.some((f) => f.value === "Initiator") ? (
                                  <th>
                                    Initiator
                                    <OverlayTrigger
                                      rootClose
                                      trigger="click"
                                      placement="right"
                                      overlay={InitiatorPopover(this.state.columns, this.setSelectedFilterField, initiators)}
                                    >
                                      <FontAwesomeIcon
                                        className={
                                          this.state.columns.find((f) => f.value === "Initiator").filter === ""
                                            ? "float-right text-muted"
                                            : "float-right text-primary"
                                        }
                                        icon={faFilter}
                                        title="Filter"
                                      />
                                    </OverlayTrigger>
                                  </th>
                                ) : null}
                                {this.state.columns.some((f) => f.value === "Maintenance Foreman") ? (
                                  <th>
                                    Maintenance Admin
                                    <OverlayTrigger
                                      rootClose
                                      trigger="click"
                                      placement="right"
                                      overlay={ForemanPopover(this.state.columns, this.setSelectedFilterField, maintenanceForemans)}
                                    >
                                      <FontAwesomeIcon
                                        className={
                                          this.state.columns.find((f) => f.value === "Maintenance Foreman").filter === ""
                                            ? "float-right text-muted"
                                            : "float-right text-primary"
                                        }
                                        icon={faFilter}
                                        title="Filter"
                                      />
                                    </OverlayTrigger>
                                  </th>
                                ) : null}
                                {this.state.columns.some((f) => f.value === "Operational Unit") ? (
                                  <th>
                                    Operational Unit
                                    <OverlayTrigger
                                      rootClose
                                      trigger="click"
                                      placement="right"
                                      overlay={OperationalUnitPopover(this.state.columns, this.setSelectedFilterField, operationalUnits)}
                                    >
                                      <FontAwesomeIcon
                                        className={
                                          this.state.columns.find((f) => f.value === "Operational Unit").filter === ""
                                            ? "float-right text-muted"
                                            : "float-right text-primary"
                                        }
                                        icon={faFilter}
                                        title="Filter"
                                      />
                                    </OverlayTrigger>
                                  </th>
                                ) : null}
                                {this.state.columns.some((f) => f.value === "Artisan") ? (
                                  <th>
                                    Artisan
                                    <OverlayTrigger
                                      rootClose
                                      trigger="click"
                                      placement="right"
                                      overlay={ArtisanPopover(this.state.columns, this.setSelectedFilterField, artisans)}
                                    >
                                      <FontAwesomeIcon
                                        className={
                                          this.state.columns.find((f) => f.value === "Artisan").filter === ""
                                            ? "float-right text-muted"
                                            : "float-right text-primary"
                                        }
                                        icon={faFilter}
                                        title="Filter"
                                      />
                                    </OverlayTrigger>
                                  </th>
                                ) : null}
                                {this.state.columns.some((f) => f.value === "Artisan Handover") ? (
                                  <th>
                                    Artisan Handover
                                    <OverlayTrigger
                                      rootClose
                                      trigger="click"
                                      placement="right"
                                      overlay={ArtisanHandOverPopover(this.state.columns, artisanHandovers, this.setSelectedFilterField)}
                                    >
                                      <FontAwesomeIcon
                                        className={
                                          this.state.columns.find((f) => f.value === "Artisan Handover").filter === ""
                                            ? "float-right text-muted"
                                            : "float-right text-primary"
                                        }
                                        icon={faFilter}
                                        title="Filter"
                                      />
                                    </OverlayTrigger>
                                  </th>
                                ) : null}
                                {this.state.columns.some((f) => f.value === "Maintenance Type") ? (
                                  <th>
                                    Maintenance Type
                                    <OverlayTrigger
                                      rootClose
                                      trigger="click"
                                      placement="right"
                                      overlay={MaintenancePopover(this.state.columns, this.setSelectedFilterField, maintenanceTypes)}
                                    >
                                      <FontAwesomeIcon
                                        className={
                                          this.state.columns.find((f) => f.value === "Maintenance Type").filter === ""
                                            ? "float-right text-muted"
                                            : "float-right text-primary"
                                        }
                                        icon={faFilter}
                                        title="Filter"
                                      />
                                    </OverlayTrigger>
                                  </th>
                                ) : null}
                                {this.state.columns.some((f) => f.value === "Machine Number") ? (
                                  <th>
                                    Machine Number
                                    <OverlayTrigger
                                      rootClose
                                      trigger="click"
                                      placement="right"
                                      overlay={MachineRegisterPopover(machineRegisters, this.state.columns, this.setSelectedFilterField)}
                                    >
                                      <FontAwesomeIcon
                                        className={
                                          this.state.columns.find((f) => f.value === "Machine Number").filter === ""
                                            ? "float-right text-muted"
                                            : "float-right text-primary"
                                        }
                                        icon={faFilter}
                                        title="Filter"
                                      />
                                    </OverlayTrigger>
                                  </th>
                                ) : null}
                                {this.state.columns.some((f) => f.value === "Title") ? <th>Title</th> : null}
                                {this.state.columns.some((f) => f.value === "TargetCloseDate") ? <th>Target Close Date</th> : null}
                                {this.state.columns.some((f) => f.value === "Source") ? (
                                  <th>
                                    Source
                                    <OverlayTrigger
                                      rootClose
                                      trigger="click"
                                      placement="right"
                                      overlay={SourcePopover(this.state.columns, this.setSelectedFilterField, sources)}
                                    >
                                      <FontAwesomeIcon
                                        className={
                                          this.state.columns.find((f) => f.value === "Source").filter === ""
                                            ? "float-right text-muted"
                                            : "float-right text-primary"
                                        }
                                        icon={faFilter}
                                        title="Filter"
                                      />
                                    </OverlayTrigger>
                                  </th>
                                ) : null}
                                <th></th>
                              </tr>
                            </thead>
                            <tbody className="scroll">
                              {this.state.loadingJobCards ? (
                                <tr>
                                  <td colSpan={8}>
                                    <Spinner className="ml-auto mr-auto d-block" animation="grow" />
                                  </td>
                                </tr>
                              ) : (
                                jobsCards.map((jobCard: IJobCard) => {
                                  const tooltip = (
                                    <Tooltip id="tooltip" style={{ maxWidth: 300 }}>
                                      {jobCard.name} <br />
                                    </Tooltip>
                                  );
                                  return (
                                    <tr className={moment(jobCard.targetDate).isBefore() && jobCard.statusId !== 5 ? "table-danger" : ""}>
                                      <td>
                                        {this.state.company.jobCardPrefix !== null && this.state.company.jobCardPrefix !== ""
                                          ? this.state.company.jobCardPrefix
                                          : "Job Card"}
                                        {jobCard.jobCardNumber?.toString().padStart(6, "0")}
                                      </td>
                                      <td className="status-column">
                                        {" "}
                                        <Badge variant={EStatusColours[jobCard.statusId]}>
                                          {jobCard.planned && jobCard.source === "Planned" && jobCard.statusId === 1
                                            ? "Planned"
                                            : EJobCardStatus[jobCard.statusId]}
                                        </Badge>
                                      </td>
                                      <td>{moment(jobCard.createdOn).format("DD/MM/yyyy")}</td>
                                      <td>{moment(jobCard.date).format("DD/MM/yyyy")}</td>

                                      {this.state.columns.some((f) => f.value === "Initiator") ? <td>{jobCard.createdByText}</td> : null}
                                      {this.state.columns.some((f) => f.value === "Maintenance Foreman") ? <td>{jobCard.maintenanceForemanText}</td> : null}
                                      {this.state.columns.some((f) => f.value === "Operational Unit") ? <td>{jobCard.operationalUnitText}</td> : null}
                                      {this.state.columns.some((f) => f.value === "Artisan") ? <td>{jobCard.artisanText}</td> : null}
                                      {this.state.columns.some((f) => f.value === "Artisan Handover") ? (
                                        <td>{(jobCard as any).artisanHandovers.map((m: any) => m.artisanName).join(", ")}</td>
                                      ) : null}
                                      {this.state.columns.some((f) => f.value === "Maintenance Type") ? <td>{jobCard.maintenanceTypeText}</td> : null}
                                      {this.state.columns.some((f) => f.value === "Machine Number") ? <td>{jobCard.machineNumber}</td> : null}
                                      {this.state.columns.some((f) => f.value === "Title") ? (
                                        <OverlayTrigger placement="left" overlay={tooltip}>
                                          <td>{jobCard.name.length > 25 ? jobCard.name.substring(0, 25) + "..." : jobCard.name}</td>
                                        </OverlayTrigger>
                                      ) : null}
                                      {this.state.columns.some((f) => f.value === "TargetCloseDate") ? (
                                        <td>{jobCard.targetDate ? moment(jobCard.targetDate).format("DD/MM/yyyy") : ""}</td>
                                      ) : null}
                                      {this.state.columns.some((f) => f.value === "Source") ? <td>{jobCard.source}</td> : null}

                                      <td>
                                        <Dropdown as={ButtonGroup}>
                                          <Button variant="info" size="sm" onClick={() => this.openJobCard(jobCard)} title="Open Job Card">
                                            <FontAwesomeIcon icon={faListAlt} />
                                          </Button>
                                          <Button
                                            variant="primary"
                                            size="sm"
                                            title="Download Job Card Files and Reports"
                                            onClick={() =>
                                              this.downloadJobCardDetail(jobCard.id as string, `${jobCard.jobCardNumber?.toString().padStart(6, "0")}`)
                                            }
                                          >
                                            <FontAwesomeIcon icon={faFileArchive} />
                                          </Button>
                                          {(isAdmin || isSA) && jobCard.statusId !== 6 ? (
                                            <Button variant="danger" size="sm" title="Cancel CAR" onClick={() => this.cancelJobCard(jobCard)}>
                                              <FontAwesomeIcon icon={faTimes} />
                                            </Button>
                                          ) : null}
                                        </Dropdown>
                                      </td>
                                    </tr>
                                  );
                                })
                              )}
                            </tbody>
                          </div>
                        </Table>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <MaintenanceStats company={this.state.company} />
          </Col>

          <Col lg={3}>
            <Button className="d-none d-lg-block" variant="primary" onClick={() => this.newJobCardButtonClick()} block>
              Initiate New{" "}
              {this.state.company.jobCardPrefix !== "" && this.state.company.jobCardPrefix !== null ? this.state.company.jobCardPrefix : "Job Card"}
            </Button>

            {(this.context.selectedCompany.plannedMaintenance || isSA) && (isAdmin || isMaintenance) && (
              <Button className="d-none d-lg-block" variant="primary" onClick={() => this.newPlannedJobCardButtonClick()} block>
                Create/View Planned{" "}
                {this.state.company.jobCardPrefix !== "" && this.state.company.jobCardPrefix !== null ? this.state.company.jobCardPrefix : "Job Card"}s
              </Button>
            )}

            <br />
            <div>
              <Card style={{ width: "100%" }}>
                <Card.Header>
                  <Card.Title>Recently Opened</Card.Title>
                </Card.Header>
                <Card.Body>
                  {this.state.loadingNotifications ? (
                    <Spinner className="ml-auto mr-auto d-block" animation="grow" />
                  ) : (
                    this.state.lastOpenedJobCards.map((lastOpenedJobCard) => {
                      return (
                        <Toast style={{ maxWidth: "1200px", width: "100%" }}>
                          <Toast.Header closeButton={false}>
                            <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
                            <strong className="mr-auto">{lastOpenedJobCard.jobCardNumber}</strong>
                            <small>{moment(lastOpenedJobCard.dateOpened).fromNow()}</small>
                          </Toast.Header>
                          <Toast.Body>
                            {lastOpenedJobCard.jobCardId ? (
                              <Button size="sm" onClick={() => this.openJobCardFromUrl(lastOpenedJobCard.jobCardId)}>
                                Open{" "}
                                {this.state.company.jobCardPrefix !== "" && this.state.company.jobCardPrefix !== null
                                  ? this.state.company.jobCardPrefix
                                  : "Job Card"}
                              </Button>
                            ) : null}
                          </Toast.Body>
                        </Toast>
                      );
                    })
                  )}
                </Card.Body>
              </Card>
            </div>
            <div>
              <Card style={{ width: "100%" }} className="mt-2">
                <Card.Header>
                  <Card.Title>Notifications</Card.Title>
                </Card.Header>
                <Card.Body>
                  {this.state.loadingNotifications ? (
                    <Spinner className="ml-auto mr-auto d-block" animation="grow" />
                  ) : (
                    this.state.notifications.map((notification: IJobCardNotification) => {
                      return (
                        <Toast style={{ maxWidth: "1200px", width: "100%" }} onClose={() => this.readNotification(notification.id)}>
                          <Toast.Header>
                            <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
                            <strong className="mr-auto">{notification.title}</strong>
                            <small>{moment(notification.createdOn).fromNow()}</small>
                          </Toast.Header>
                          <Toast.Body>
                            {notification.message} <br />
                            {notification.jobCardId ? (
                              <Button size="sm" onClick={() => this.openJobCardNotification(notification.jobCardId, notification.id)}>
                                Open{" "}
                                {this.state.company.jobCardPrefix !== "" && this.state.company.jobCardPrefix !== null
                                  ? this.state.company.jobCardPrefix
                                  : "Job Card"}
                              </Button>
                            ) : null}
                          </Toast.Body>
                        </Toast>
                      );
                    })
                  )}
                </Card.Body>
              </Card>
            </div>
          </Col>
        </Row>

        <Modal onHide={() => this.onCloseCancelDialog()} centered size={"sm"} show={this.state.showCancelDialog} animation={true}>
          <Modal.Header>
            <Modal.Title className="mr-auto">
              Job Card Number : <Badge variant="info">{this.state.selectedJobCard?.jobCardNumber?.toString().padStart(6, "0")}</Badge>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Label>Cancellation Reason</Form.Label>
            <Form.Control as="textarea" rows={3} value={this.state.cancelReason} onChange={(e) => this.setState({ cancelReason: e.target.value })} />
          </Modal.Body>
          <ModalFooter>
            <Button variant="secondary" onClick={() => this.onCloseCancelDialog()}>
              Close
            </Button>
            <Button variant="danger" disabled={!this.state.cancelReason} onClick={() => this.onCancel()}>
              {!this.state.loading ? "Cancel Job Card" : <Spinner animation="grow" size="sm" />}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

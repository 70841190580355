import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { deleteData, getData, postData } from "../../helpers/Data";
import { faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";

import { AuthContext } from "../../helpers/Contexts";
import { ConfirmationDialog } from "../../helpers/ConfirmationDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IDeparmentList } from "../../entities/Departments";
import React from "react";
import Select from "react-select";

interface IProps {}

interface IState {
  text: string;
  departments: IDeparmentList[];
  showEditDialog: boolean;
  selectedDepartment: IDeparmentList;
  users: any;
}

export default class Departments extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      text: "TEST",
      departments: [],
      showEditDialog: false,
      selectedDepartment: { name: "", headOfDepartment: "", companyId: "", headOfDepartmentText: "" },
      users: [],
    };
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.refresh();
    this.refreshUsers();
  }

  refresh() {
    getData("departments", { companyId: this.context.selectedCompany?.id }).then((data) => {
      this.setState({ departments: data });
    });
  }

  buttonClick = () => {
    ConfirmationDialog({ confirmFunction: this.complete, title: "YOH?", message: "Are you sure you wanna yoh?", confirmButtonText: "YEAH" });
  };

  complete = () => {
    this.setState({ text: "YOOOOOOOOOOO" });
  };

  openEditDialog = (department: IDeparmentList) => {
    this.setState({ selectedDepartment: { ...department }, showEditDialog: true });
  };

  openCreateDialog = () => {
    this.setState({ selectedDepartment: { name: "", headOfDepartment: "", companyId: "", headOfDepartmentText: "" }, showEditDialog: true });
  };

  onClose = () => {
    this.setState({ showEditDialog: false, selectedDepartment: { name: "", headOfDepartment: "", companyId: "", headOfDepartmentText: "" } });
  };

  onSave = () => {
    let dept = this.state.selectedDepartment;
    dept.companyId = this.context.selectedCompany.id;
    postData("departments", dept).then((res) => {
      this.setState({ showEditDialog: false, selectedDepartment: { name: "", headOfDepartment: "", companyId: "", headOfDepartmentText: "" } }, () =>
        this.refresh()
      );
    });
  };

  setSelectedDepartmentField = (value: any, field: keyof IDeparmentList) => {
    let dept = this.state.selectedDepartment;
    dept = this.prop(dept, field, value);
    this.setState({ selectedDepartment: dept });
  };

  prop<T, K extends keyof T>(obj: T, key: K, newVal: any) {
    obj[key] = newVal;
    return obj;
  }

  refreshUsers = () => {
    getData("users/GetUserDDL", { companyId: this.context.selectedCompany?.id }).then((data) => {
      this.setState({ users: data });
    });
  };

  delete = (id: string | undefined) => {
    deleteData("departments/", { id: id }).then((data) => {
      this.refresh();
    });
  };

  render() {
    return (
      <div>
        <Card style={{ width: "100%" }}>
          <Card.Header>
            <Card.Title>
              Departments
              <span className="float-right">
                <Button variant="primary" className="" onClick={() => this.openCreateDialog()}>
                  Add Department
                </Button>
              </span>
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col>
                <Table striped hover size="sm">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>HOD</th>
                      <th style={{ width: "100px" }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.departments.length > 0
                      ? this.state.departments.map((department: IDeparmentList) => {
                          return (
                            <tr>
                              <td>{department.name}</td>
                              <td>{department.headOfDepartmentText}</td>
                              <td>
                                <Button variant="primary" size="sm" onClick={() => this.openEditDialog(department)}>
                                  <FontAwesomeIcon icon={faEdit} /> Edit
                                </Button>
                                <Button variant="danger" size="sm" onClick={() => this.delete(department.id)}>
                                  <FontAwesomeIcon icon={faTimes} />
                                </Button>
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Modal show={this.state.showEditDialog} onHide={() => this.onClose()} animation={false}>
          <Modal.Header>
            <Modal.Title>{this.state.selectedDepartment.id ? "Edit " + this.state.selectedDepartment.name : "Create Department"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  value={this.state.selectedDepartment.name}
                  placeholder="Enter Department Name"
                  onChange={(e) => this.setSelectedDepartmentField(e.target.value, "name")}
                />
              </Form.Group>
              <Form.Group controlId="formHorizontalEmail">
                <Form.Label>Head of Department</Form.Label>

                <Select
                  options={this.state.users}
                  onChange={(inv: any) => {
                    this.setSelectedDepartmentField(inv.value, "headOfDepartment");
                  }}
                  value={
                    this.state.selectedDepartment.headOfDepartment
                      ? this.state.users.filter((option: any) => option.value === this.state.selectedDepartment.headOfDepartment)
                      : null
                  }
                  placeholder="Select HOD"
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.onClose()}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                this.onSave();
              }}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

import { Dropdown, Nav, Navbar, Spinner } from "react-bootstrap";
import { Redirect, Route, BrowserRouter as Router, Switch } from "react-router-dom";

import { AuthContext } from "../helpers/Contexts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LogOut } from "../helpers/Data";
import Login from "../screens/auth/Login";
import NavLinks from "./NavLinks";
import { NavRoutes } from "../navigation/Routes";
import NoCompany from "../screens/auth/NoCompany";
import React from "react";
import UserProfileDialog from "../screens/auth/UserProfile";
import VerifyEmail from "../screens/auth/VerifyEmail";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import routes from "../navigation/Routes";

interface IProps {
  location?: any;
}

interface IState {
  openLogoutDialog: boolean;
  showProfileDialog: boolean;
}

export default class Navigator extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = { openLogoutDialog: false, showProfileDialog: false };
  }

  hideToggle() {
    try {
      var selectorId = document.querySelector(".mdl-layout") ?? <div></div>;
      (selectorId as any).MaterialLayout.toggleDrawer();
    } catch {}
  } //Important to hide the drawer when navigating with React Router. Remember to add 'onClick={() => this.hideToggle()}' to links

  handleLogoutDialogClose = (confirm: boolean = false) => {
    if (confirm) {
      this.logout();
    }
    this.setState({ openLogoutDialog: false });
  };

  handleLogoutDialogOpen = () => {
    this.setState({ openLogoutDialog: true });
  };

  logout = () => {
    LogOut().then(() => {
      localStorage.removeItem("bearer-token");
      localStorage.removeItem("bearer-token");
      localStorage.removeItem("lastOpenedJobCards");
      this.context.setAuthed(false);
      window.location.reload();
    });
  };

  closeProfileDialog = () => {
    this.setState({ showProfileDialog: false });
  };

  showProfileDialog = () => {
    this.setState({ showProfileDialog: true });
  };

  static contextType = AuthContext;

  render() {
    if (this.context.isAuthed && this.context.userChecked && this.context.companies.length === 0 && !this.context.User?.roles.includes("SuperAdministrators")) {
      return <NoCompany />;
    } else if (!this.context.isAuthed && this.context.userChecked) {
      return (
        <Router>
          <Switch>
            <Route exact path="/verifyemail" render={() => <VerifyEmail />} />
            <Route exact render={() => <Login />} />
          </Switch>
        </Router>
      );
    } else if (this.context.isAuthed && this.context.userChecked) {
      return (
        <Router>
          <Redirect exact from="/" to="/home" />
          <div style={{}}>
            <Navbar bg="dark" variant="dark" expand="lg">
              <Navbar.Brand href="/home">Quality Manager</Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <NavLinks routes={routes} roles={this.context.User?.roles} location={window.location} />

                <Nav className="ml-auto">
                  <Navbar.Text>
                    <a className="" onClick={() => this.showProfileDialog()}>
                      <FontAwesomeIcon icon={faUser} /> {this.context.User.emailAddress} &nbsp;
                    </a>
                  </Navbar.Text>
                  <Dropdown>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                      {this.context.selectedCompany.name}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {this.context.companies
                        .filter((f: any) => f.active === true)
                        .map((company: any) => {
                          return (
                            <Dropdown.Item key={company.id} onClick={() => this.context.setCompany(company)}>
                              {company.name}
                            </Dropdown.Item>
                          );
                        })}
                    </Dropdown.Menu>
                  </Dropdown>
                  <Nav.Link className="nav-link" onClick={() => this.logout()}>
                    Logout
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>

            <div style={{ width: "calc(100% - 40px)", margin: "20px" }}>
              <NavRoutes roles={this.context.User.roles} />
            </div>
          </div>

          <UserProfileDialog user={this.context.User} onClose={() => this.closeProfileDialog()} show={this.state.showProfileDialog} />
        </Router>
      );
    } else {
      return (
        <div style={{ backgroundColor: "#fff", height: "100vh" }}>
          <Spinner className="align-middle ml-auto mr-auto my-5 d-block" animation="border" />
        </div>
      );
    }
  }
}

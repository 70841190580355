import * as htmlToImage from "html-to-image";

import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Col,
  Dropdown,
  Form,
  InputGroup,
  Modal,
  ModalFooter,
  OverlayTrigger,
  Popover,
  Row,
  Spinner,
  Table,
  Toast,
  Tooltip,
} from "react-bootstrap";
import { EStatus, EStatusColours } from "../../helpers/enums/enums";
import { faCalendar, faFileArchive, faFileExcel, faFilter, faListAlt, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { getData, getReport, postData } from "../../helpers/Data";

import { AuthContext } from "../../helpers/Contexts";
import CarStats from "../car/carStats";
import { ConfirmationDialog } from "../../helpers/ConfirmationDialog";
import CorrectiveActionDialog from "../car/CorrectiveActionDialog";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HelpModal from "../../modals/helpModal";
import { ICategoryList } from "../../entities/Categories";
import { ICompanyName } from "../../entities/Company";
import { ICorrectiveAction } from "../../entities/CorrectiveAction";
import { IDeparmentList } from "../../entities/Departments";
import { INotification } from "../../entities/Notification";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import Select from "react-select";
import download from "downloadjs";
import moment from "moment";

interface IProps extends RouteComponentProps {}

interface IState {
  cancelReason?: string;
  companyCategories: ICategoryList[];
  cars: [];
  columns: any[];
  company: ICompanyName;
  filterItems: number[];
  fromDate?: Date;
  departments: IDeparmentList[];
  loading: boolean;
  loadingCars: boolean;
  loadingNotifications: boolean;
  notifications: INotification[];
  selectedCar?: ICorrectiveAction;
  showCARDialog: boolean;
  showCancelDialog: boolean;
  text: string;
  toDate?: Date;
}

const columns = [
  { value: "Initiator", label: "Initiator", filter: "" },
  { value: "HOD", label: "Head of Department", filter: "" },
  { value: "Investigator", label: "Investigator", filter: "" },
  { value: "Category", label: "Category", filter: "" },
  { value: "Department", label: "Department", filter: "" },
  { value: "Title", label: "Title", filter: "" },
  { value: "TargetCloseDate", label: "Target Close Date", filter: "" },
  { value: "Source", label: "Source", filter: "" },
];

export default class CorrectiveActionPage extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      text: "",
      filterItems: [],
      showCARDialog: false,
      companyCategories: [],
      cars: [],
      company: { companyPrefix: "", jobCardPrefix: "" },
      departments: [],
      notifications: [],
      loading: false,
      loadingCars: true,
      loadingNotifications: true,
      fromDate: moment().startOf("month").subtract(1, "months").toDate(),
      toDate: moment().toDate(),
      showCancelDialog: false,

      columns: localStorage.getItem("columns")
        ? JSON.parse(localStorage.getItem("columns") ?? "")
        : [
            { value: "Initiator", label: "Initiator", filter: "" },
            { value: "HOD", label: "Head of Department", filter: "" },
            { value: "Investigator", label: "Investigator", filter: "" },
            { value: "Title", label: "Title", filter: "" },
            { value: "TargetCloseDate", label: "Target Close Date", filter: "" },
            // { value: "Source", label: "Source", filter: "" },
          ],
    };
  }
  static contextType = AuthContext;

  componentDidMount = () => {
    this.refreshCARList();
    this.refreshNotificationList();
    this.getCompany();
    this.getCategories();
    this.getDepartments();
    var id = new URLSearchParams(this.props.location.search).get("id");
    var maintenance = new URLSearchParams(this.props.location.search).get("maintenance");

    if (id != null && !maintenance) {
      this.openCARFromUrl(id);
    }
  };

  refreshCARList = () => {
    getData("car", {
      text: this.state.text,
      companyId: this.context.selectedCompany?.id,
      statusIDs: this.state.filterItems,
      toDate: moment(this.state.toDate).format("DD/MM/YYYY"),
      fromDate: moment(this.state.fromDate).format("DD/MM/YYYY"),
    }).then((data) => {
      this.setState({ cars: data, loadingCars: false });
    });
  };

  getCategories = () => {
    getData("categories", { companyId: this.context.selectedCompany?.id }).then((data) => {
      this.setState({ companyCategories: data });
    });
  };

  getCompany = () => {
    getData("companies/" + this.context.selectedCompany.id).then((data) => {
      this.setState({ company: data });
    });
  };

  getDepartments = () => {
    getData("departments", { companyId: this.context.selectedCompany?.id }).then((data) => {
      this.setState({ departments: data });
    });
  };

  refreshNotificationList = () => {
    getData("notifications/list").then((data) => {
      this.setState({ notifications: data, loadingNotifications: false });
    });
  };

  buttonClick = () => {
    ConfirmationDialog({ confirmFunction: this.complete, title: "YOH?", message: "Are you sure you wanna yoh?", confirmButtonText: "YEAH" });
  };

  complete = () => {
    this.setState({ text: "YOOOOOOOOOOO" });
  };

  carDialogComplete = () => {};

  newCARButtonClick = () => {
    this.setState({
      showCARDialog: true,
      selectedCar: {
        date: new Date(),
        name: "",
        companyId: "",
        createdBy: "",
        createdOn: new Date(),
        status: "",
        statusId: 1,
        categoryId: "",
        departmentId: "",
        description: "",
        headOfDepartment: "",
        headOfDepartmentText: "",
        comments: [],
        costs: [],
        files: [],
        categoryText: "",
        departmentText: "",
      },
    });
  };

  closeCARDialog = () => {
    this.refreshCARList();
    this.setState({
      showCARDialog: false,
      selectedCar: {
        date: new Date(),
        name: "",
        companyId: "",
        createdBy: "",
        createdOn: new Date(),
        status: "",
        statusId: 1,
        categoryId: "",
        departmentId: "",
        description: "",
        headOfDepartment: "",
        headOfDepartmentText: "",
        comments: [],
        costs: [],
        files: [],
        categoryText: "",
        departmentText: "",
      },
    });
  };

  openCAR = (car: ICorrectiveAction) => {
    this.setState({ selectedCar: car, showCARDialog: true });
  };

  openCARFromUrl = (carId: string) => {
    this.setState({
      showCARDialog: true,
      selectedCar: {
        date: new Date(),
        name: "",
        companyId: "",
        createdBy: "",
        createdOn: new Date(),
        status: "",
        statusId: 1,
        categoryId: "",
        departmentId: "",
        description: "",
        headOfDepartment: "",
        headOfDepartmentText: "",
        comments: [],
        costs: [],
        id: carId,
        files: [],
        categoryText: "",
        departmentText: "",
      },
    });
  };

  openCARNotification = (carId: string, notificationId: string) => {
    this.setState(
      {
        showCARDialog: true,
        selectedCar: {
          date: new Date(),
          name: "",
          companyId: "",
          createdBy: "",
          createdOn: new Date(),
          status: "",
          statusId: 1,
          categoryId: "",
          departmentId: "",
          description: "",
          headOfDepartment: "",
          headOfDepartmentText: "",
          comments: [],
          costs: [],
          id: carId,
          files: [],
          categoryText: "",
          departmentText: "",
        },
      },
      () => this.readNotification(notificationId)
    );
  };

  readNotification = (id: string) => {
    postData("notifications/read", { id: id }).then((res) => {
      this.refreshNotificationList();
    });
  };

  setFilterItems = (items: any) => {
    let filterItems: any[] = [];
    items.forEach((element: any) => {
      filterItems.push(element.value);
    });
    this.setState({ filterItems }, () => this.refreshCARList());
  };

  setColumnItems = (items: any) => {
    let columns: any[] = [];
    items.forEach((element: any) => {
      columns.push(element);
    });
    this.setState({ columns }, () => localStorage.setItem("columns", JSON.stringify(columns)));
  };

  setSelectedFilterField = (value: any, field: keyof any, column: string) => {
    let cols = this.state.columns;
    let col = cols.find((f) => f.value === column);
    cols = cols.filter((f) => f !== col);
    col = this.prop(col, field, value.target.value);
    cols.push(col);
    this.setState({ columns: cols }, () => {
      localStorage.setItem("columns", JSON.stringify(cols));
    });
  };

  prop<T, K extends keyof T>(obj: T, key: K, newVal: any) {
    obj[key] = newVal;
    return obj;
  }

  downloadCARList = () => {
    getReport(
      "car/download",
      {
        companyId: this.context.selectedCompany?.id,
        statusIDs: this.state.filterItems,
        toDate: moment(this.state.toDate).format("DD/MM/YYYY"),
        fromDate: moment(this.state.fromDate).format("DD/MM/YYYY"),
      },
      `${this.state.company.companyPrefix} Register`
    ).then((data) => {});
  };

  downloadCARDetail = (id: string | undefined, name: string) => {
    getReport("car/carreport", { id: id }, `${this.state.company.companyPrefix}${name}`).then((data) => {});
  };

  cancelCAR = (car: ICorrectiveAction) => {
    this.setState({ selectedCar: car, showCancelDialog: true });
  };

  onCancel = () => {
    this.setState({ loading: true });
    postData("car/cancel", { CARId: this.state.selectedCar?.id, Reasons: this.state.cancelReason }).then((res) => {
      this.refreshCARList();
      this.setState({
        showCancelDialog: false,
        selectedCar: {
          date: new Date(),
          name: "",
          companyId: "",
          createdBy: "",
          createdOn: new Date(),
          status: "",
          statusId: 1,
          categoryId: "",
          departmentId: "",
          description: "",
          headOfDepartment: "",
          headOfDepartmentText: "",
          comments: [],
          costs: [],
          files: [],
          categoryText: "",
          departmentText: "",
        },
        cancelReason: undefined,
        loading: false,
      });
    });
  };

  onCloseCancelDialog = () => {
    this.setState({
      showCancelDialog: false,
      selectedCar: {
        date: new Date(),
        name: "",
        companyId: "",
        createdBy: "",
        createdOn: new Date(),
        status: "",
        statusId: 1,
        categoryId: "",
        departmentId: "",
        description: "",
        headOfDepartment: "",
        headOfDepartmentText: "",
        comments: [],
        costs: [],
        files: [],
        categoryText: "",
        departmentText: "",
      },
      cancelReason: undefined,
    });
  };

  saveChartToPng = async (chartId: string, name: string) => {
    const canvas = document.getElementById(chartId) as HTMLCanvasElement;
    htmlToImage
      .toPng(canvas)
      .then((dataUrl) => {
        download(dataUrl, `${name}.png`);
      })
      .catch((err) => {
        console.error("Oops, something went wrong!", err);
      });
  };

  render() {
    let isSA = this.context.User.roles.includes("SuperAdministrators");
    let isAdmin = this.context.User.roles.includes("Administrators");

    const options = [
      { value: "1", label: "Initiated" },
      { value: "2", label: "Assigned" },
      { value: "3", label: "Pending HOD Sign Off" },
      { value: "4", label: "Pending QA Admin sign Off" },
      { value: "5", label: "Closed" },
      { value: "6", label: "Cancelled" },
    ];

    let initiators = Array.from(new Set(this.state.cars.map((car: ICorrectiveAction) => car.createdByText)));
    let hods = Array.from(new Set(this.state.cars.map((car: ICorrectiveAction) => car.headOfDepartmentText)));
    let departments = Array.from(new Set(this.state.departments.map((dep: IDeparmentList) => dep.name)));
    let categories = Array.from(new Set(this.state.companyCategories.map((cat: ICategoryList) => cat.name)));
    let investigators = Array.from(new Set(this.state.cars.map((car: ICorrectiveAction) => car.investigatorText)));
    let sources = Array.from(new Set(this.state.cars.map((car: ICorrectiveAction) => car.source)));

    const initiatorPopover = (
      <Popover id="popover-basic">
        <Popover.Title as="h3">Filter</Popover.Title>
        <Popover.Content>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Initiator</Form.Label>
            <Form.Control
              value={this.state.columns.find((f) => f.value === "Initiator")?.filter}
              as="select"
              onChange={(e) => this.setSelectedFilterField(e, "filter", "Initiator")}
            >
              <option></option>
              {initiators.map((i) => {
                return <option>{i}</option>;
              })}
            </Form.Control>
          </Form.Group>
        </Popover.Content>
      </Popover>
    );

    const hodPopover = (
      <Popover id="popover-basic">
        <Popover.Title as="h3">Filter</Popover.Title>
        <Popover.Content>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Head Of Dept.</Form.Label>
            <Form.Control
              value={this.state.columns.find((f) => f.value === "HOD")?.filter}
              as="select"
              onChange={(e) => this.setSelectedFilterField(e, "filter", "HOD")}
            >
              <option></option>
              {hods.map((i) => {
                return <option>{i}</option>;
              })}
            </Form.Control>
          </Form.Group>
        </Popover.Content>
      </Popover>
    );

    const departmentPopover = (
      <Popover id="popover-basic">
        <Popover.Title as="h3">Filter</Popover.Title>
        <Popover.Content>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Deparment </Form.Label>
            <Form.Control
              value={this.state.columns.find((f) => f.value === "Department")?.filter}
              as="select"
              onChange={(e) => this.setSelectedFilterField(e, "filter", "Department")}
            >
              <option></option>
              {departments.map((i) => {
                return <option>{i}</option>;
              })}
            </Form.Control>
          </Form.Group>
        </Popover.Content>
      </Popover>
    );

    const categoryPopover = (
      <Popover id="popover-basic">
        <Popover.Title as="h3">Filter</Popover.Title>
        <Popover.Content>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Category </Form.Label>
            <Form.Control
              value={this.state.columns.find((f) => f.value === "Category")?.filter}
              as="select"
              onChange={(e) => this.setSelectedFilterField(e, "filter", "Category")}
            >
              <option></option>
              {categories.map((i) => {
                return <option>{i}</option>;
              })}
            </Form.Control>
          </Form.Group>
        </Popover.Content>
      </Popover>
    );

    const investigatorPopover = (
      <Popover id="popover-basic">
        <Popover.Title as="h3">Filter</Popover.Title>
        <Popover.Content>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Investigator </Form.Label>
            <Form.Control
              value={this.state.columns.find((f) => f.value === "Investigator")?.filter}
              as="select"
              onChange={(e) => this.setSelectedFilterField(e, "filter", "Investigator")}
            >
              <option></option>
              {investigators.map((i) => {
                return <option>{i}</option>;
              })}
            </Form.Control>
          </Form.Group>
        </Popover.Content>
      </Popover>
    );

    const sourcePopover = (
      <Popover id="popover-basic">
        <Popover.Title as="h3">Filter</Popover.Title>
        <Popover.Content>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Source </Form.Label>
            <Form.Control
              value={this.state.columns.find((f) => f.value === "Source")?.filter}
              as="select"
              onChange={(e) => this.setSelectedFilterField(e, "filter", "Source")}
            >
              <option></option>
              {sources.map((i) => {
                return <option>{i}</option>;
              })}
            </Form.Control>
          </Form.Group>
        </Popover.Content>
      </Popover>
    );

    let cars: any[] = this.state.cars;

    if (this.state.columns.find((f) => f.value === "Initiator") && this.state.columns.find((f) => f.value === "Initiator").filter !== "") {
      cars = cars.filter((f: ICorrectiveAction) => f.createdByText?.trim() === this.state.columns.find((f) => f.value === "Initiator").filter);
    }
    if (this.state.columns.find((f) => f.value === "HOD") && this.state.columns.find((f) => f.value === "HOD").filter !== "") {
      cars = cars.filter((f: ICorrectiveAction) => f.headOfDepartmentText?.trim() === this.state.columns.find((f) => f.value === "HOD").filter);
    }
    if (this.state.columns.find((f) => f.value === "Department") && this.state.columns.find((f) => f.value === "Department").filter !== "") {
      cars = cars.filter((f: ICorrectiveAction) => f.departmentText?.trim() === this.state.columns.find((f) => f.value === "Department").filter);
    }
    if (this.state.columns.find((f) => f.value === "Category") && this.state.columns.find((f) => f.value === "Category").filter !== "") {
      cars = cars.filter((f: ICorrectiveAction) => f.categoryText?.trim() === this.state.columns.find((f) => f.value === "Category").filter);
    }
    if (this.state.columns.find((f) => f.value === "Investigator") && this.state.columns.find((f) => f.value === "Investigator").filter !== "") {
      cars = cars.filter((f: ICorrectiveAction) => f.investigatorText?.trim() === this.state.columns.find((f) => f.value === "Investigator").filter);
    }

    if (this.state.columns.find((f) => f.value === "Source") && this.state.columns.find((f) => f.value === "Source").filter !== "") {
      cars = cars.filter((f: ICorrectiveAction) => f.source?.trim() === this.state.columns.find((f) => f.value === "Source").filter);
    }

    return (
      <div>
        <CorrectiveActionDialog onClose={() => this.closeCARDialog()} user={this.context.User} show={this.state.showCARDialog} car={this.state.selectedCar} />

        <Row>
          <Col lg={9}>
            <div className="d-lg-none">
              <Button variant="primary" onClick={() => this.newCARButtonClick()} block>
                Initiate New CAR
              </Button>
              <br />
            </div>

            <Row>
              <Col lg={12}>
                <Card style={{ width: "100%" }}>
                  <Card.Header>
                    <Card.Title>
                      <Button className="float-right" variant="primary" onClick={() => this.downloadCARList()}>
                        <FontAwesomeIcon icon={faFileExcel} /> Download {this.state.company.companyPrefix != null ? this.state.company.companyPrefix : "CAR"}{" "}
                        Register
                      </Button>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Table borderless size="sm">
                      <tbody>
                        <tr>
                          <td style={{ width: "50px" }} className="text-right align-middle">
                            From
                          </td>
                          <td style={{ width: "200px" }}>
                            {" "}
                            <DatePicker
                              customInput={
                                <InputGroup className="">
                                  <Form.Control placeholder="Date" value={moment(this.state.fromDate).format("DD/MM/YYYY")} width="100%" />
                                  <InputGroup.Append>
                                    <InputGroup.Text id="basic-addon2">
                                      <FontAwesomeIcon icon={faCalendar} />
                                    </InputGroup.Text>
                                  </InputGroup.Append>
                                </InputGroup>
                              }
                              onChange={(date: Date) => this.setState({ fromDate: date }, () => this.refreshCARList())}
                            />
                          </td>
                          <td style={{ width: "50px" }} className="text-right align-middle">
                            To
                          </td>
                          <td style={{ width: "200px" }}>
                            <DatePicker
                              customInput={
                                <InputGroup className="">
                                  <Form.Control placeholder="Date" value={moment(this.state.toDate).format("DD/MM/YYYY")} width="100%" />
                                  <InputGroup.Append>
                                    <InputGroup.Text id="basic-addon2">
                                      <FontAwesomeIcon icon={faCalendar} />
                                    </InputGroup.Text>
                                  </InputGroup.Append>
                                </InputGroup>
                              }
                              onChange={(date: Date) => this.setState({ toDate: date }, () => this.refreshCARList())}
                            />
                          </td>
                          <td style={{ width: "200px" }} className="d-none d-lg-table-cell">
                            {" "}
                            <Select options={options} isMulti onChange={(e) => this.setFilterItems(e)} placeholder="Filter Status" />
                          </td>
                          <td className="d-none d-lg-table-cell">
                            <InputGroup className="">
                              <Form.Control
                                placeholder="Search"
                                value={this.state.text}
                                width="100%"
                                onChange={(e) => this.setState({ text: e.target.value })}
                              />
                              <InputGroup.Append>
                                {this.state.text !== "" && (
                                  <Button
                                    variant="outline-secondary"
                                    style={{ padding: "0px 12px" }}
                                    onClick={() => this.setState({ text: "" }, () => this.refreshCARList())}
                                  >
                                    <FontAwesomeIcon icon={faTimes} />
                                  </Button>
                                )}
                                <Button variant="secondary" style={{ padding: "0px 12px" }} onClick={() => this.refreshCARList()}>
                                  <FontAwesomeIcon icon={faSearch} />
                                </Button>
                              </InputGroup.Append>
                            </InputGroup>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={4} className="d-lg-none">
                            <Select options={options} isMulti onChange={(e) => this.setFilterItems(e)} placeholder="Filter Status" />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={4} className="d-lg-none">
                            <InputGroup className="">
                              <Form.Control
                                placeholder="Search"
                                value={this.state.text}
                                width="100%"
                                onChange={(e) => this.setState({ text: e.target.value })}
                              />
                              <InputGroup.Append>
                                {this.state.text !== "" && (
                                  <Button
                                    variant="outline-secondary"
                                    style={{ padding: "0px 12px" }}
                                    onClick={() => this.setState({ text: "" }, () => this.refreshCARList())}
                                  >
                                    <FontAwesomeIcon icon={faTimes} />
                                  </Button>
                                )}
                                <Button variant="secondary" style={{ padding: "0px 12px" }} onClick={() => this.refreshCARList()}>
                                  <FontAwesomeIcon icon={faSearch} />
                                </Button>
                              </InputGroup.Append>
                            </InputGroup>
                          </td>
                        </tr>
                        <tr>
                          {" "}
                          <td style={{ width: "50px" }} className="text-right align-middle">
                            Columns
                          </td>
                          <td colSpan={7}>
                            {" "}
                            <Select options={columns} value={this.state.columns} isMulti onChange={(e) => this.setColumnItems(e)} placeholder="Set Columns" />
                          </td>
                        </tr>
                      </tbody>
                    </Table>

                    <Row>
                      <Col></Col>
                      <Col></Col>
                      <Col className="text-right">
                        <HelpModal />
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <br />
                        <Table striped hover size="sm" bordered responsive className="sticky-header-table">
                          <div style={{ maxHeight: "50vh" }}>
                            <thead className="sticky-header">
                              <tr>
                                <th>#</th>
                                <th className="status-column">Status</th>
                                <th>Initiation Date</th>
                                <th>Incident Date</th>
                                {this.state.columns.some((f) => f.value === "Initiator") ? (
                                  <th>
                                    Initiator
                                    <OverlayTrigger rootClose trigger="click" placement="right" overlay={initiatorPopover}>
                                      <FontAwesomeIcon
                                        className={
                                          this.state.columns.find((f) => f.value === "Initiator").filter === ""
                                            ? "float-right text-muted"
                                            : "float-right text-primary"
                                        }
                                        icon={faFilter}
                                        title="Filter"
                                      />
                                    </OverlayTrigger>
                                  </th>
                                ) : null}
                                {this.state.columns.some((f) => f.value === "HOD") ? (
                                  <th>
                                    HOD
                                    <OverlayTrigger rootClose trigger="click" placement="right" overlay={hodPopover}>
                                      <FontAwesomeIcon
                                        className={
                                          this.state.columns.find((f) => f.value === "HOD").filter === ""
                                            ? "float-right text-muted"
                                            : "float-right text-primary"
                                        }
                                        icon={faFilter}
                                        title="Filter"
                                      />
                                    </OverlayTrigger>
                                  </th>
                                ) : null}
                                {this.state.columns.some((f) => f.value === "Department") ? (
                                  <th>
                                    Department
                                    <OverlayTrigger rootClose trigger="click" placement="right" overlay={departmentPopover}>
                                      <FontAwesomeIcon
                                        className={
                                          this.state.columns.find((f) => f.value === "Department").filter === ""
                                            ? "float-right text-muted"
                                            : "float-right text-primary"
                                        }
                                        icon={faFilter}
                                        title="Filter"
                                      />
                                    </OverlayTrigger>
                                  </th>
                                ) : null}
                                {this.state.columns.some((f) => f.value === "Investigator") ? (
                                  <th>
                                    Investigator
                                    <OverlayTrigger rootClose trigger="click" placement="right" overlay={investigatorPopover}>
                                      <FontAwesomeIcon
                                        className={
                                          this.state.columns.find((f) => f.value === "Investigator").filter === ""
                                            ? "float-right text-muted"
                                            : "float-right text-primary"
                                        }
                                        icon={faFilter}
                                        title="Filter"
                                      />
                                    </OverlayTrigger>
                                  </th>
                                ) : null}
                                {this.state.columns.some((f) => f.value === "Category") ? (
                                  <th>
                                    Category
                                    <OverlayTrigger rootClose trigger="click" placement="right" overlay={categoryPopover}>
                                      <FontAwesomeIcon
                                        className={
                                          this.state.columns.find((f) => f.value === "Category").filter === ""
                                            ? "float-right text-muted"
                                            : "float-right text-primary"
                                        }
                                        icon={faFilter}
                                        title="Filter"
                                      />
                                    </OverlayTrigger>
                                  </th>
                                ) : null}
                                {this.state.columns.some((f) => f.value === "Title") ? <th>Title</th> : null}
                                {this.state.columns.some((f) => f.value === "TargetCloseDate") ? <th>Target Close Date</th> : null}
                                {this.state.columns.some((f) => f.value === "Source") ? (
                                  <th>
                                    Source
                                    <OverlayTrigger rootClose trigger="click" placement="right" overlay={sourcePopover}>
                                      <FontAwesomeIcon
                                        className={
                                          this.state.columns.find((f) => f.value === "Source").filter === ""
                                            ? "float-right text-muted"
                                            : "float-right text-primary"
                                        }
                                        icon={faFilter}
                                        title="Filter"
                                      />
                                    </OverlayTrigger>
                                  </th>
                                ) : null}
                                <th></th>
                              </tr>
                            </thead>
                            <tbody className="scroll">
                              {this.state.loadingCars ? (
                                <tr>
                                  <td colSpan={8}>
                                    <Spinner className="ml-auto mr-auto d-block" animation="grow" />
                                  </td>
                                </tr>
                              ) : (
                                cars.map((car: ICorrectiveAction) => {
                                  const tooltip = (
                                    <Tooltip id="tooltip" style={{ maxWidth: 300 }}>
                                      {car.name} <br />
                                    </Tooltip>
                                  );
                                  return (
                                    <tr className={moment(car.targetDate).isBefore() && car.statusId !== 5 ? "table-danger" : ""}>
                                      <td>
                                        {this.state.company.companyPrefix != null ? this.state.company.companyPrefix : "CAR"}
                                        {car.correctiveActionNumber?.toString().padStart(6, "0")}
                                      </td>
                                      <td className="status-column">
                                        <Badge variant={EStatusColours[car.statusId]}>
                                          {this.context.selectedCompany.finalReviewTitle && car.statusId === 4
                                            ? `Pending ${this.context.selectedCompany.finalReviewTitle} Sign Off`
                                            : EStatus[car.statusId]}
                                        </Badge>
                                      </td>
                                      <td>{moment(car.createdOn).format("DD/MM/yyyy")}</td>
                                      <td>{moment(car.date).format("DD/MM/yyyy")}</td>

                                      {this.state.columns.some((f) => f.value === "Initiator") ? <td>{car.createdByText}</td> : null}
                                      {this.state.columns.some((f) => f.value === "HOD") ? <td>{car.headOfDepartmentText}</td> : null}
                                      {this.state.columns.some((f) => f.value === "Department") ? <td>{car.departmentText}</td> : null}
                                      {this.state.columns.some((f) => f.value === "Investigator") ? <td>{car.investigatorText}</td> : null}
                                      {this.state.columns.some((f) => f.value === "Category") ? <td>{car.categoryText}</td> : null}
                                      {this.state.columns.some((f) => f.value === "Title") ? (
                                        <OverlayTrigger placement="left" overlay={tooltip}>
                                          <td>{car.name.length > 25 ? car.name.substring(0, 25) + "..." : car.name}</td>
                                        </OverlayTrigger>
                                      ) : null}
                                      {this.state.columns.some((f) => f.value === "TargetCloseDate") ? (
                                        <td>{car.targetDate ? moment(car.targetDate).format("DD/MM/yyyy") : ""}</td>
                                      ) : null}
                                      {this.state.columns.some((f) => f.value === "Source") ? <td>{car.source}</td> : null}
                                      <td>
                                        <Dropdown as={ButtonGroup}>
                                          <Button variant="info" size="sm" onClick={() => this.openCAR(car)} title="Open CAR">
                                            <FontAwesomeIcon icon={faListAlt} />
                                          </Button>
                                          <Button
                                            variant="primary"
                                            size="sm"
                                            title="Download Detail CAR Files and Reports"
                                            onClick={() => this.downloadCARDetail(car.id, car.correctiveActionNumber?.toString().padStart(6, "0") as string)}
                                          >
                                            <FontAwesomeIcon icon={faFileArchive} />
                                          </Button>
                                          {(isAdmin || isSA) && car.statusId !== 6 ? (
                                            <Button variant="danger" size="sm" title="Cancel CAR" onClick={() => this.cancelCAR(car)}>
                                              <FontAwesomeIcon icon={faTimes} />
                                            </Button>
                                          ) : null}
                                        </Dropdown>
                                      </td>
                                    </tr>
                                  );
                                })
                              )}
                            </tbody>
                          </div>
                        </Table>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <CarStats company={this.state.company} />
          </Col>

          <Col lg={3}>
            <Button className="d-none d-lg-block" variant="primary" onClick={() => this.newCARButtonClick()} block>
              Initiate New {this.state.company.companyPrefix != null ? this.state.company.companyPrefix : "CAR"}
            </Button>
            <br />
            <div>
              <Card style={{ width: "100%" }}>
                <Card.Header>
                  <Card.Title>Notifications</Card.Title>
                </Card.Header>
                <Card.Body>
                  {this.state.loadingNotifications ? (
                    <Spinner className="ml-auto mr-auto d-block" animation="grow" />
                  ) : (
                    this.state.notifications.map((notification: INotification) => {
                      return (
                        <Toast style={{ maxWidth: "1200px", width: "100%" }} onClose={() => this.readNotification(notification.id)}>
                          <Toast.Header>
                            <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
                            <strong className="mr-auto">{notification.title}</strong>
                            <small>{moment(notification.createdOn).fromNow()}</small>
                          </Toast.Header>
                          <Toast.Body>
                            {notification.message} <br />
                            {notification.correctiveActionReportId ? (
                              <Button size="sm" onClick={() => this.openCARNotification(notification.correctiveActionReportId, notification.id)}>
                                Open {this.state.company.companyPrefix != null ? this.state.company.companyPrefix : "CAR"}
                              </Button>
                            ) : null}
                          </Toast.Body>
                        </Toast>
                      );
                    })
                  )}
                </Card.Body>
              </Card>
            </div>
          </Col>
        </Row>

        <Modal onHide={() => this.onCloseCancelDialog()} centered size={"sm"} show={this.state.showCancelDialog} animation={true}>
          <Modal.Header>
            <Modal.Title className="mr-auto">
              CAR Number : <Badge variant="info">{this.state.selectedCar?.correctiveActionNumber?.toString().padStart(6, "0")}</Badge>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Label>Cancellation Reason</Form.Label>
            <Form.Control as="textarea" rows={3} value={this.state.cancelReason} onChange={(e) => this.setState({ cancelReason: e.target.value })} />
          </Modal.Body>
          <ModalFooter>
            <Button variant="secondary" onClick={() => this.onCloseCancelDialog()}>
              Close
            </Button>
            <Button variant="danger" disabled={!this.state.cancelReason} onClick={() => this.onCancel()}>
              {!this.state.loading ? "Cancel CAR" : <Spinner animation="grow" size="sm" />}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export interface ITotalCostQualityDashboardViewModel {
  totalsPerDepartment: ITotalsWithDoubleViewModel;
  totalsPerCompany: ITotalsWithDoubleViewModel;
  maxValue: number;
}

export interface ITotalsWithDoubleViewModel {
  colour: string;
  monthTotals: number[];
  labels: string[];
}

export function createTotalCostQualityDashboardViewModel(): ITotalCostQualityDashboardViewModel {
  return {
    totalsPerDepartment: createTotalsWithDoubleViewModel(),
    totalsPerCompany: createTotalsWithDoubleViewModel(),
    maxValue: 0,
  };
}

export function createTotalsWithDoubleViewModel(): ITotalsWithDoubleViewModel {
  return {
    colour: "",
    monthTotals: [],
    labels: [],
  };
}

import { Form, Popover } from "react-bootstrap";

export const MachineRegisterPopover = (
  machineRegisters: string[],
  maintenanceColumns: {
    value: string;
    label: string;
    filter: string;
  }[],
  setSelectedFilterField: (value: any, field: keyof any, column: string) => void
) => {
  return (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Filter</Popover.Title>
      <Popover.Content>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Label>Machine</Form.Label>
          <Form.Control
            value={maintenanceColumns.find((f) => f.value === "Machine Number")?.filter}
            as="select"
            onChange={(e) => setSelectedFilterField(e, "filter", "Machine Number")}
          >
            <option></option>
            {machineRegisters.map((i) => {
              return <option>{i}</option>;
            })}
          </Form.Control>
        </Form.Group>
      </Popover.Content>
    </Popover>
  );
};

export const ArtisanHandOverPopover = (
  maintenanceColumns: {
    value: string;
    label: string;
    filter: string;
  }[],
  artisanHandovers: string[],
  setSelectedFilterField: (value: any, field: keyof any, column: string) => void
) => {
  return (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Filter</Popover.Title>
      <Popover.Content>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Label>Handover</Form.Label>
          <Form.Control
            value={maintenanceColumns.find((f) => f.value === "Artisan Handover")?.filter}
            as="select"
            onChange={(e) => setSelectedFilterField(e, "filter", "Artisan Handover")}
          >
            <option></option>
            {artisanHandovers.map((i) => {
              return <option>{i}</option>;
            })}
          </Form.Control>
        </Form.Group>
      </Popover.Content>
    </Popover>
  );
};

export const InitiatorPopover = (
  maintenanceColumns: {
    value: string;
    label: string;
    filter: string;
  }[],
  setSelectedFilterField: (value: any, field: keyof any, column: string) => void,
  initiators: (string | undefined)[]
) => {
  return (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Filter</Popover.Title>
      <Popover.Content>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Label>Initiator</Form.Label>
          <Form.Control
            value={maintenanceColumns.find((f) => f.value === "Initiator")?.filter}
            as="select"
            onChange={(e) => setSelectedFilterField(e, "filter", "Initiator")}
          >
            <option></option>
            {initiators.map((i) => {
              return <option>{i}</option>;
            })}
          </Form.Control>
        </Form.Group>
      </Popover.Content>
    </Popover>
  );
};

export const ForemanPopover = (
  maintenanceColumns: {
    value: string;
    label: string;
    filter: string;
  }[],
  setSelectedFilterField: (value: any, field: keyof any, column: string) => void,
  maintenanceForemans: string[]
) => {
  return (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Filter</Popover.Title>
      <Popover.Content>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Label>Maintenance Admin</Form.Label>
          <Form.Control
            value={maintenanceColumns.find((f) => f.value === "Maintenance Foreman")?.filter}
            as="select"
            onChange={(e) => setSelectedFilterField(e, "filter", "Maintenance Foreman")}
          >
            <option></option>
            {maintenanceForemans.map((i) => {
              return <option>{i}</option>;
            })}
          </Form.Control>
        </Form.Group>
      </Popover.Content>
    </Popover>
  );
};

export const OperationalUnitPopover = (
  maintenanceColumns: {
    value: string;
    label: string;
    filter: string;
  }[],
  setSelectedFilterField: (value: any, field: keyof any, column: string) => void,
  maintenanceForemans: string[]
) => {
  return (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Filter</Popover.Title>
      <Popover.Content>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Label>Operational Unit</Form.Label>
          <Form.Control
            value={maintenanceColumns.find((f) => f.value === "Operational Unit")?.filter}
            as="select"
            onChange={(e) => setSelectedFilterField(e, "filter", "Operational Unit")}
          >
            <option></option>
            {maintenanceForemans.map((i) => {
              return <option>{i}</option>;
            })}
          </Form.Control>
        </Form.Group>
      </Popover.Content>
    </Popover>
  );
};

export const MaintenancePopover = (
  maintenanceColumns: {
    value: string;
    label: string;
    filter: string;
  }[],
  setSelectedFilterField: (value: any, field: keyof any, column: string) => void,
  maintenanceTypes: string[]
) => {
  return (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Filter</Popover.Title>
      <Popover.Content>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Label>Maintenance Type</Form.Label>
          <Form.Control
            value={maintenanceColumns.find((f) => f.value === "Maintenance Type")?.filter}
            as="select"
            onChange={(e) => setSelectedFilterField(e, "filter", "Maintenance Type")}
          >
            <option></option>
            {maintenanceTypes.map((i) => {
              return <option>{i}</option>;
            })}
          </Form.Control>
        </Form.Group>
      </Popover.Content>
    </Popover>
  );
};

export const ArtisanPopover = (
  maintenanceColumns: {
    value: string;
    label: string;
    filter: string;
  }[],
  setSelectedFilterField: (value: any, field: keyof any, column: string) => void,
  artisans: (string | undefined)[]
) => {
  return (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Filter</Popover.Title>
      <Popover.Content>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Label>Artisan</Form.Label>
          <Form.Control
            value={maintenanceColumns.find((f) => f.value === "Artisan")?.filter}
            as="select"
            onChange={(e) => setSelectedFilterField(e, "filter", "Artisan")}
          >
            <option></option>
            {artisans.map((i) => {
              return <option>{i}</option>;
            })}
          </Form.Control>
        </Form.Group>
      </Popover.Content>
    </Popover>
  );
};

export const SourcePopover = (
  maintenanceColumns: {
    value: string;
    label: string;
    filter: string;
  }[],
  setSelectedFilterField: (value: any, field: keyof any, column: string) => void,
  sources: (string | undefined)[]
) => {
  return (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Filter</Popover.Title>
      <Popover.Content>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Label>Source</Form.Label>
          <Form.Control
            value={maintenanceColumns.find((f) => f.value === "Source")?.filter}
            as="select"
            onChange={(e) => setSelectedFilterField(e, "filter", "Source")}
          >
            <option></option>
            {sources.map((i) => {
              return <option>{i}</option>;
            })}
          </Form.Control>
        </Form.Group>
      </Popover.Content>
    </Popover>
  );
};

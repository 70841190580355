import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { IUser, IUserList } from "../../entities/User";
import { deleteData, getData, postDataNonJSON } from "../../helpers/Data";
import { faCheck, faEdit, faMailBulk, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";

import { AuthContext } from "../../helpers/Contexts";
import { ConfirmationDialog } from "../../helpers/ConfirmationDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PaginationHelper from "../../helpers/PaginationHelper";
import React from "react";
import { toast } from "react-toastify";

interface IProps {}

interface IState {
  text: string;
  users: IUserList[];
  showEditDialog: boolean;
  selectedUser: IUser;
  page: number;
  pageCount: number;
  totalCount: number;
}

export default class Users extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      text: "TEST",
      users: [],
      showEditDialog: false,
      selectedUser: {
        emailAddress: "",
        fullName: "",
        firstName: "",
        maintenanceNotifications: false,
        mobileNumber: "",
        notifications: false,
        password: "",
        surname: "",
        role: "",
        roles: [],
      },
      page: 1,
      pageCount: 1,
      totalCount: 1,
    };
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.refresh();
  }

  pageClick = (number: number) => {
    this.setState({ page: number }, () => this.refresh());
  };

  refresh() {
    getData("users/paged", { page: this.state.page, pageSize: 10, companyId: this.context.selectedCompany?.id }).then((data) => {
      this.setState({ pageCount: data.pageCount, page: data.page, totalCount: data.total, users: data.items });
    });
  }

  buttonClick = () => {
    ConfirmationDialog({ confirmFunction: this.complete, title: "YOH?", message: "Are you sure you wanna yoh?", confirmButtonText: "YEAH" });
  };

  complete = () => {
    this.setState({ text: "YOOOOOOOOOOO" });
  };

  openEditDialog = (user: IUserList) => {
    this.setState({ selectedUser: { ...user }, showEditDialog: true });
  };

  openCreateDialog = () => {
    this.setState({
      selectedUser: {
        emailAddress: "",
        fullName: "",
        firstName: "",
        maintenanceNotifications: false,
        mobileNumber: "",
        notifications: false,
        password: "",
        surname: "",
        role: "",
        roles: [],
      },
      showEditDialog: true,
    });
  };

  onClose = () => {
    this.setState({
      showEditDialog: false,
      selectedUser: {
        emailAddress: "",
        fullName: "",
        firstName: "",
        maintenanceNotifications: false,
        mobileNumber: "",
        notifications: false,
        password: "",
        surname: "",
        role: "",
        roles: [],
      },
    });
  };

  deleteCompanyUserMappingConfirmation = (user: IUserList) => {
    ConfirmationDialog({
      confirmFunction: () => this.deleteCompanyUserMapping(user),
      title: "Delete User",
      message: `Are you sure you want to remove user ${user.fullName} from ${this.context.selectedCompany.name}?`,
      confirmButtonText: "Yes",
    });
  };

  deleteCompanyUserMapping = (user: IUserList) => {
    deleteData("companyusermappings/", { apiuserid: user.id, companyid: this.context.selectedCompany.id }).then((res) => {
      this.refresh();
    });
  };

  onSave = () => {
    let user = this.state.selectedUser;
    user.companyId = this.context.selectedCompany.id;
    postDataNonJSON("users", user).then((res) => {
      if (res.ok) {
        toast.success((user.id ? "Updated " : "Created ") + user.firstName + " " + user.surname);
      } else {
        toast.error("Could not " + (user.id ? "update " : "created ") + user.firstName + " " + user.surname);
      }
      this.setState(
        {
          showEditDialog: false,
          selectedUser: {
            emailAddress: "",
            fullName: "",
            firstName: "",
            maintenanceNotifications: false,
            mobileNumber: "",
            notifications: false,
            password: "",
            surname: "",
            role: "",
            roles: [],
          },
        },
        () => this.refresh()
      );
    });
  };

  addOrRemoveRole = (checked: boolean, role: string) => {
    let tempRoles = this.state.selectedUser.roles;
    if (checked) {
      tempRoles.push(role);
    } else {
      let index = tempRoles.indexOf(role);
      tempRoles.splice(index, 1);
    }
    this.setSelectedUserField(tempRoles, "roles");
  };

  setSelectedUserField = (value: any, field: keyof IUser) => {
    let user = this.state.selectedUser;
    user = this.prop(user, field, value);
    this.setState({ selectedUser: user });
  };

  prop<T, K extends keyof T>(obj: T, key: K, newVal: any) {
    obj[key] = newVal;
    return obj;
  }

  resetUser = (userId: string) => {
    postDataNonJSON("users/ResetAndResendUserDetail?userId=" + userId, {}).then((res) => {
      if (res.ok) {
        this.refresh();
        toast.success("Reset and Resent User Info");
      }
    });
  };

  render() {
    let isSA = this.context.User.roles.includes("SuperAdministrators");

    return (
      <div>
        <Card style={{ width: "100%" }}>
          <Card.Header>
            <Card.Title>
              Users
              <span className="float-right">
                <Button variant="primary" className="" onClick={() => this.openCreateDialog()}>
                  Add User
                </Button>
              </span>
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col>
                <Table striped hover size="sm">
                  <thead>
                    <tr>
                      <th>Full Name</th>
                      <th>Email</th>
                      <th>Roles</th>
                      <th style={{ textAlign: "center" }}>Corrective Action Notifications</th>
                      {(this.context.selectedCompany.maintenance || isSA) && <th style={{ textAlign: "center" }}>Maintenance Notifications</th>}
                      <th style={{ textAlign: "center" }}>Active</th>
                      <th style={{ width: "230px" }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.users.length > 0
                      ? this.state.users.map((user: IUserList) => {
                          return (
                            <tr key={user.id}>
                              <td>{user.fullName}</td>
                              <td>{user.emailAddress}</td>
                              <td>{user.roles.map((role) => `${role === "RegisteredUsers" ? "CAR User" : role}${user.roles.length > 1 ? "," : ""} `)}</td>
                              <td style={{ textAlign: "center" }}>
                                {user.notifications ? <FontAwesomeIcon icon={faCheck} color="green" /> : <FontAwesomeIcon icon={faTimes} color="red" />}
                              </td>
                              {(this.context.selectedCompany.maintenance || isSA) && (
                                <td style={{ textAlign: "center" }}>
                                  {user.maintenanceNotifications ? (
                                    <FontAwesomeIcon icon={faCheck} color="green" />
                                  ) : (
                                    <FontAwesomeIcon icon={faTimes} color="red" />
                                  )}
                                </td>
                              )}
                              <td style={{ textAlign: "center" }}>
                                {!user.isBlocked ? <FontAwesomeIcon icon={faCheck} color="green" /> : <FontAwesomeIcon icon={faTimes} color="red" />}
                              </td>
                              <td>
                                <Button variant="danger" size="sm" onClick={() => this.deleteCompanyUserMappingConfirmation(user)}>
                                  <FontAwesomeIcon icon={faTrash} /> Delete
                                </Button>
                                <Button variant="primary" size="sm" onClick={() => this.openEditDialog(user)}>
                                  <FontAwesomeIcon icon={faEdit} /> Edit
                                </Button>
                                <Button variant="info" size="sm" onClick={() => this.resetUser(user.id)}>
                                  <FontAwesomeIcon icon={faMailBulk} />
                                  Resend
                                </Button>
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </Table>
                {this.state.users.length > 0 ? (
                  <PaginationHelper page={this.state.page} pageCount={this.state.pageCount} total={this.state.totalCount} pageCallback={this.pageClick} />
                ) : null}
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Modal show={this.state.showEditDialog} onHide={() => this.onClose()} animation={false}>
          <Modal.Header>
            <Modal.Title>{this.state.selectedUser.id ? "Edit " + this.state.selectedUser.fullName : "Create User"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  value={this.state.selectedUser.emailAddress}
                  disabled={this.state.selectedUser.id !== undefined}
                  placeholder="Enter Email Address"
                  onChange={(e) => this.setSelectedUserField(e.target.value, "emailAddress")}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  value={this.state.selectedUser.firstName}
                  placeholder="Enter Name"
                  onChange={(e) => this.setSelectedUserField(e.target.value, "firstName")}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Surname</Form.Label>
                <Form.Control
                  value={this.state.selectedUser.surname}
                  placeholder="Enter Surname"
                  onChange={(e) => this.setSelectedUserField(e.target.value, "surname")}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  value={this.state.selectedUser.mobileNumber}
                  placeholder="Enter Mobile Number"
                  onChange={(e) => this.setSelectedUserField(e.target.value, "mobileNumber")}
                />
              </Form.Group>

              {/* <Form.Group>
                                <Form.Label>Password</Form.Label>
                                <Form.Control value={this.state.selectedUser.password} placeholder="Enter Password" type="password" onChange={(e => this.setSelectedUserField(e.target.value, "password"))} />
                                <Form.Text id="passwordHelpBlock" muted>
                                    Leave password field blank if you don't wish to change it.
                                </Form.Text>
                            </Form.Group> */}

              <Form.Group controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  checked={this.state.selectedUser.roles.includes("Administrators")}
                  label="Administrator"
                  onChange={(e) => {
                    this.addOrRemoveRole(e.target.checked, "Administrators");
                  }}
                />
              </Form.Group>
              <Form.Group controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  checked={this.state.selectedUser.roles.includes("RegisteredUsers")}
                  label="CAR User"
                  onChange={(e) => {
                    this.addOrRemoveRole(e.target.checked, "RegisteredUsers");
                  }}
                />
              </Form.Group>
              {(this.context.selectedCompany.maintenance || isSA) && (
                <Form.Group controlId="formBasicCheckbox">
                  <Form.Check
                    type="checkbox"
                    checked={this.state.selectedUser.roles.includes("MaintenanceUsers")}
                    label="Maintenance User"
                    onChange={(e) => {
                      this.addOrRemoveRole(e.target.checked, "MaintenanceUsers");
                    }}
                  />
                </Form.Group>
              )}

              <Form.Group controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  checked={this.state.selectedUser.isBlocked}
                  label="Is Blocked"
                  onChange={(e) => {
                    this.setSelectedUserField(e.target.checked, "isBlocked");
                  }}
                />
              </Form.Group>

              <Form.Group controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  checked={this.state.selectedUser.notifications}
                  label="Corrective Action Notifications"
                  onChange={(e) => {
                    this.setSelectedUserField(e.target.checked, "notifications");
                  }}
                />
              </Form.Group>

              {(this.context.selectedCompany.maintenance || isSA) && (
                <Form.Group>
                  <Form.Check
                    type="checkbox"
                    checked={this.state.selectedUser.maintenanceNotifications}
                    label="Maintenance Notifications"
                    onChange={(e) => this.setSelectedUserField(e.target.checked, "maintenanceNotifications")}
                  />
                </Form.Group>
              )}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.onClose()}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                this.onSave();
              }}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export const maintenanceColumns = [
  { value: "Initiator", label: "Initiator", filter: "" },
  { value: "Maintenance Foreman", label: "Maintenance Admin", filter: "" },
  { value: "Artisan", label: "Artisan", filter: "" },
  { value: "Artisan Handover", label: "Artisan Handover", filter: "" },
  { value: "Machine Number", label: "Machine Number", filter: "" },
  { value: "Maintenance Type", label: "Maintenance Type", filter: "" },
  { value: "Operational Unit", label: "Operational Unit", filter: "" },
  { value: "Title", label: "Title", filter: "" },
  { value: "TargetCloseDate", label: "Target Close Date", filter: "" },
  { value: "Source", label: "Source", filter: "" },
];

import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { deleteData, getData, postData } from "../../helpers/Data";
import { faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";

import { AuthContext } from "../../helpers/Contexts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IMachineRegister } from "../../entities/MachineRegister";
import { IMachineSectionDropDownList } from "../../entities/MachineSections";
import { IOperationalUnitList } from "../../entities/OperationalUnits";
import React from "react";
import Select from "react-select";

interface IProps {}

interface IState {
  machineRegisters: IMachineRegister[];
  showEditDialog: boolean;
  selectedMachineRegister: IMachineRegister;
  operationalUnits: IOperationalUnitList[];
  machineSections: IMachineSectionDropDownList[];
}

export default class MachineRegister extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      machineRegisters: [],
      showEditDialog: false,
      selectedMachineRegister: { operationalUnitId: "", operationalUnit: "", companyId: "", machineNumber: "", machineSectionLinkings: [] },
      operationalUnits: [],
      machineSections: [],
    };
  }
  static contextType = AuthContext;

  componentDidMount() {
    this.refresh();
  }

  refresh() {
    getData("machineRegisters", { companyId: this.context.selectedCompany?.id }).then((data) => {
      this.setState({ machineRegisters: data });
    });
    getData("operationalUnits/GetOperationalUnitDDL", { companyId: this.context.selectedCompany?.id }).then((data) => {
      this.setState({ operationalUnits: data });
    });
    getData("machineSection/GetMachineSectionDDL", { companyId: this.context.selectedCompany?.id }).then((data) => {
      this.setState({ machineSections: data });
    });
  }

  openEditDialog = (machine: IMachineRegister) => {
    this.setState({ selectedMachineRegister: { ...machine }, showEditDialog: true });
  };

  openCreateDialog = () => {
    this.setState({
      selectedMachineRegister: { operationalUnitId: "", operationalUnit: "", companyId: "", machineNumber: "", machineSectionLinkings: [] },
      showEditDialog: true,
    });
  };

  onClose = () => {
    this.setState({
      showEditDialog: false,
      selectedMachineRegister: { operationalUnitId: "", operationalUnit: "", companyId: "", machineNumber: "", machineSectionLinkings: [] },
    });
  };

  onSave = () => {
    let machine = this.state.selectedMachineRegister;
    machine.companyId = this.context.selectedCompany.id;

    postData("machineRegisters", machine).then((data) => {
      this.setState(
        {
          showEditDialog: false,
          selectedMachineRegister: { operationalUnitId: "", operationalUnit: "", companyId: "", machineNumber: "", machineSectionLinkings: [] },
        },
        () => this.refresh()
      );
    });
  };

  setSelectedMachineRegisterField = (value: any, field: keyof IMachineRegister) => {
    let machine = this.state.selectedMachineRegister;
    machine = this.prop(machine, field, value);
    this.setState({ selectedMachineRegister: machine });
  };

  prop<T, K extends keyof T>(obj: T, key: K, newVal: any) {
    obj[key] = newVal;
    return obj;
  }

  delete = (id: string | undefined) => {
    deleteData("machineRegisters/", { id: id }).then((data) => {
      this.refresh();
    });
  };

  render() {
    return (
      <div>
        <Card>
          <Card.Header>
            <Card.Title>
              Machine Registers
              <span className="float-right">
                <Button variant="primary" className="" onClick={() => this.openCreateDialog()}>
                  Add Machine Register
                </Button>
              </span>
            </Card.Title>
          </Card.Header>

          <Card.Body>
            <Row>
              <Col>
                <Table striped hover size="sm">
                  <thead>
                    <tr>
                      <th>Machine Number</th>
                      <th>Machine Sections</th>
                      <th>Operational Unit</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.machineRegisters.length > 0
                      ? this.state.machineRegisters.map((machine, index) => {
                          return (
                            <tr key={index}>
                              <td>{machine.machineNumber}</td>
                              <td>{machine.machineSectionLinkings.map((m) => m.machineSection.name).join(" | ")}</td>
                              <td>{machine.operationalUnit}</td>
                              <td className="text-end">
                                <Button variant="primary" size="sm" onClick={() => this.openEditDialog(machine)}>
                                  <FontAwesomeIcon icon={faEdit} /> Edit
                                </Button>
                                <Button variant="danger" size="sm" onClick={() => this.delete(machine.id)}>
                                  <FontAwesomeIcon icon={faTimes} />
                                </Button>
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Modal show={this.state.showEditDialog} onHide={() => this.onClose()} animation={false}>
          <Modal.Header>
            <Modal.Title>{this.state.selectedMachineRegister.id ? "Edit" : "Create Machine Register"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Machine Number</Form.Label>
                <Form.Control
                  placeholder="Machine Number"
                  type="text"
                  value={this.state.selectedMachineRegister.machineNumber}
                  onChange={(e) => this.setSelectedMachineRegisterField(e.target.value, "machineNumber")}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Operational Unit</Form.Label>
                <Select
                  placeholder="Select Operational Unit"
                  options={this.state.operationalUnits}
                  onChange={(e: any) => this.setSelectedMachineRegisterField(e?.value, "operationalUnitId")}
                  value={
                    this.state.selectedMachineRegister.operationalUnitId
                      ? this.state.operationalUnits.filter((option: any) => option.value === this.state.selectedMachineRegister.operationalUnitId)
                      : null
                  }
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Machine Sections</Form.Label>
                <Select
                  isMulti
                  options={this.state.machineSections}
                  value={this.state.selectedMachineRegister.machineSectionLinkings.map((item: any) => ({
                    value: item.machineSection !== undefined ? item.machineSection.id : item.value,
                    label: item.machineSection !== undefined ? item.machineSection.name : item.label,
                  }))}
                  placeholder="Select Machine Sections"
                  onChange={(items) => this.setSelectedMachineRegisterField(items, "machineSectionLinkings")}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.onClose()}>
              Close
            </Button>
            <Button variant="primary" onClick={() => this.onSave()}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

import { Nav, NavDropdown } from "react-bootstrap";

import AboutDialog from "../helpers/AboutDialog";
import { AuthContext } from "../helpers/Contexts";
import { CheckRoleAccess } from "./Routes";
import { INavLink } from "../entities/Navigation";
import { NavLink } from "react-router-dom";
import NewTicketBadge from "../helpers/newTicketBadge";
import React from "react";

interface IProps {
  routes: INavLink[];
  roles: string[];
  location?: any;
}

interface IState {
  routes: INavLink[];
  showAbout: boolean;
  pageType: "car" | "maintenance";
}

export default class Navigator extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = { routes: this.props.routes.filter((f) => f.diplayOnNav === true), showAbout: false, pageType: "car" };
  }

  static contextType = AuthContext;

  showAboutDialog = () => {
    this.setState({ showAbout: true });
  };

  hideAboutDialog = () => {
    this.setState({ showAbout: false });
  };

  componentDidMount() {
    this.setPageType();
  }

  componentDidUpdate(prevProps: any) {
    this.setPageType();
  }

  setPageType() {
    var type = new URLSearchParams(window.location.search).get("type") as "car" | "maintenance";
    if (type != null && this.state.pageType !== type) {
      this.setState({ pageType: type });
    }
  }

  render() {
    let isSA = this.context.User.roles.includes("SuperAdministrators");

    return (
      <Nav className="mr-auto">
        {CheckRoleAccess(this.props.roles, this.state.routes).map((route: INavLink) => {
          if (route.children) {
            return (
              <NavDropdown key={route.path} title={route.title} id="basic-nav-dropdown" onClick={() => this.setPageType()}>
                {CheckRoleAccess(this.props.roles, route.children)
                  .filter((f: any) => !f.hideMaintenance && (f.type !== undefined ? f.type === this.state.pageType : true))
                  .map((subRoute: INavLink) => {
                    return (
                      <NavLink className="dropdown-item" to={subRoute.path} key={subRoute.path}>
                        {subRoute.title}
                      </NavLink>
                    );
                  })}
                {CheckRoleAccess(this.props.roles, route.children)
                  .filter((f: any) => f.hideMaintenance)
                  .map((subRoute: INavLink) => {
                    return (
                      ((subRoute.hideMaintenance && this.context.selectedCompany.maintenance) || isSA) && (
                        <NavLink className="dropdown-item" to={subRoute.path} key={subRoute.path}>
                          {subRoute.title}
                        </NavLink>
                      )
                    );
                  })}
              </NavDropdown>
            );
          } else {
            return (
              <NavLink className="nav-link" to={route.path.substr(0, route.path.indexOf(":"))} key={route.path}>
                {route.title}
              </NavLink>
            );
          }
        })}

        <Nav.Link className="nav-link" onClick={() => this.showAboutDialog()}>
          About
        </Nav.Link>

        {/* <NavLink className="nav-link" to={"/support"} key={"support"}>
          Support
          <NewTicketBadge />
        </NavLink> */}

        <AboutDialog onClose={() => this.hideAboutDialog()} show={this.state.showAbout} />
      </Nav>
    );
  }
}

import { Tab, Tabs } from "react-bootstrap";

import { AuthContext } from "../helpers/Contexts";
import CorrectiveActionPage from "./home/CorrectiveActionPage";
import MaintenancePage from "./home/MaintenancePage";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { getData } from "../helpers/Data";

interface IProps extends RouteComponentProps {}

interface IState {
  tabKey: "car" | "maintenance";
}

export default class Home extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      tabKey: "car",
    };
  }
  static contextType = AuthContext;

  componentDidMount = async () => {
    var maintenance = new URLSearchParams(this.props.location.search).get("maintenance");

    const companyId = new URLSearchParams(this.props.location.search).get("companyId");
    const supportId = new URLSearchParams(this.props.location.search).get("supportId");

    if (companyId && supportId) {
      const company = this.context.companies.find((c: any) => c.id.toLowerCase() === companyId.toLowerCase());
      this.context.setCompany(company, true);
      this.props.history.push(`/support?id=${supportId}`);

      return;
    }

    this.setState({
      tabKey:
        this.context.User.roles.includes("Administrators") ||
        this.context.User.roles.includes("SuperAdministrators") ||
        this.context.User.roles.includes("RegisteredUsers")
          ? "car"
          : "maintenance",
    });

    if (maintenance && maintenance === "true") {
      this.setState({ tabKey: "maintenance" });
    }

    this.setUrlParms(
      this.context.User.roles.includes("Administrators") ||
        this.context.User.roles.includes("SuperAdministrators") ||
        this.context.User.roles.includes("RegisteredUsers")
        ? "car"
        : "maintenance"
    );
  };

  setUrlParms(type: "car" | "maintenance") {
    const searchParams = new URLSearchParams();
    searchParams.set("type", type);

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: searchParams.toString(),
    });
  }

  render() {
    let isSA = this.context.User.roles.includes("SuperAdministrators");
    let isAdmin = this.context.User.roles.includes("Administrators");
    let isMaintenance = this.context.User.roles.includes("MaintenanceUsers");
    let isCarUser = this.context.User.roles.includes("RegisteredUsers");

    return (
      <div>
        <Tabs
          defaultActiveKey={isAdmin || isSA || isCarUser ? "car" : "maintenance"}
          id="uncontrolled-tab-example"
          className="mb-3"
          activeKey={this.state.tabKey}
          onSelect={(k) => {
            this.setState({ tabKey: k as "car" | "maintenance" });
            this.setUrlParms(k as "car" | "maintenance");
          }}
        >
          {(isAdmin || isSA || isCarUser) && (
            <Tab eventKey="car" title="Corrective Actions">
              <CorrectiveActionPage location={this.props.location} history={this.props.history} match={this.props.match} />
            </Tab>
          )}
          {(this.context.selectedCompany.maintenance || isSA) && (isAdmin || isMaintenance) && (
            <Tab eventKey="maintenance" title="Maintenance">
              <MaintenancePage location={this.props.location} history={this.props.history} match={this.props.match} />
            </Tab>
          )}
        </Tabs>
      </div>
    );
  }
}

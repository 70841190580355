export interface IPlannedJobCardTask {
  completedById?: string | null;
  completedByName?: string | null;
  completedDate?: string | null;
  description: string;
  id?: string | null;
  jobCardId?: string;
}

export enum EPlannedTasksFrequency {
  Once,
  Weekly,
  Monthly,
  Quarterly,
  Yearly,
}

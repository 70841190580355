export interface ICARStatsViewModel {
  carPerCategories: ICARPerCategoryViewModel[];
  carPerDepartments: ICARPerDepartmentViewModel[];
  carytdcCosts: ICARYTDCCostsViewModel;
  mtdCompletedInTarget: ICARMTDCompletedInTargetViewModel;
}

export interface ICARMTDCompletedInTargetViewModel {
  percentage: number;
}

export interface ICARYTDCCostsViewModel {
  lastMTDCost: number;
  lastYTDCost: number;
  mtdCost: number;
  ytdCost: number;
}

export interface ICARPerCategoryViewModel {
  categoryName: string;
  count: number;
}

export interface ICARPerDepartmentViewModel {
  departmentName: string;
  count: number;
}

export function createCARStatsViewModel(): ICARStatsViewModel {
  return {
    carPerCategories: [],
    carPerDepartments: [],
    carytdcCosts: createCARYTDCCostsViewModel(),
    mtdCompletedInTarget: createCARMTDCompletedInTargetViewModel(),
  };
}

export function createCARMTDCompletedInTargetViewModel(): ICARMTDCompletedInTargetViewModel {
  return {
    percentage: 0,
  };
}

export function createCARYTDCCostsViewModel(): ICARYTDCCostsViewModel {
  return {
    lastMTDCost: 0,
    lastYTDCost: 0,
    mtdCost: 0,
    ytdCost: 0,
  };
}

import * as htmlToImage from "html-to-image";

import { Button, Card, Col, Row } from "react-bootstrap";
import { IJobCardStatsViewModel, createJobCardStatsViewModel } from "../entities/maintenanceStats";
import { backgroundColor, gaugeColours } from "../helpers/Colors";

import { AuthContext } from "../helpers/Contexts";
import { Bar } from "react-chartjs-2";
import { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GaugeChart from "react-gauge-chart";
import { ICompanyName } from "../entities/Company";
import NumberFormat from "react-number-format";
import download from "downloadjs";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { getData } from "../helpers/Data";
import moment from "moment";

interface IProps {
  company: ICompanyName;
}

interface IState {
  filterItem: string;
  fromDate?: Date;
  statistics: IJobCardStatsViewModel;
  toDate?: Date;
}

const barOptions: any = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          stepSize: 1,
        },
      },
    ],
  },
  plugins: {
    legend: {
      display: true,
      position: "bottom",
    },
  },
};

export default class MaintenanceStats extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      filterItem: "0",
      fromDate: moment().startOf("month").toDate(),
      statistics: createJobCardStatsViewModel(),
      toDate: moment().endOf("day").toDate(),
    };
  }
  static contextType = AuthContext;

  componentDidMount = () => {
    this.refreshStats();
  };

  refreshStats = () => {
    getData("jobCards/stats", { companyId: this.context.selectedCompany?.id }).then((data) => {
      this.setState({ statistics: data });
    });
  };

  saveChartToPng = async (chartId: string, name: string) => {
    const canvas = document.getElementById(chartId) as HTMLCanvasElement;
    htmlToImage
      .toPng(canvas)
      .then((dataUrl) => {
        download(dataUrl, `${name}.png`);
      })
      .catch((err) => {
        console.error("Oops, something went wrong!", err);
      });
  };

  render() {
    const data = {
      labels: [""],
      datasets: this.state.statistics.jobCardPerMaintenanceTypes?.map((a: any, i: number) => {
        return {
          label: a.maintenanceTypeName,
          data: [a.count],
          backgroundColor: backgroundColor[i],
          borderWidth: 0,
        };
      }),
    };

    const dataPerOperationalUnit = {
      labels: [""],
      datasets: this.state.statistics.jobCardPerOperationalUnits?.map((a: any, i: number) => {
        return {
          label: a.operationalUnitName,
          data: [a.count],
          backgroundColor: backgroundColor[i],
          borderWidth: 0,
        };
      }),
    };

    return (
      <Row className="mt-2">
        <Col lg={3}>
          <Card style={{ width: "100%", height: "100%", textAlign: "center" }}>
            <Row>
              <Col style={{ paddingTop: "5px" }}>
                <Button
                  style={{ width: "fit-content" }}
                  variant={"success"}
                  size="sm"
                  title="Download Chart"
                  onClick={() => this.saveChartToPng("mainCosts", `Costs`)}
                >
                  <FontAwesomeIcon icon={faDownload} />
                </Button>
              </Col>
            </Row>
            <div id="mainCosts" style={{ backgroundColor: "white" }}>
              <Card.Header>
                <Card.Title>Costs</Card.Title>
              </Card.Header>
              <Card.Body>
                <h2 className="text-center text-dark">
                  MTD : <NumberFormat value={this.state.statistics.jobCardYTDCosts?.mtdCost.toFixed(0)} thousandSeparator={true} displayType="text" />
                </h2>
                <h4 className="text-center text-muted">
                  Last Month :{" "}
                  <NumberFormat value={this.state.statistics.jobCardYTDCosts?.lastMTDCost.toFixed(0)} thousandSeparator={true} displayType="text" />
                </h4>
                <hr />
                <h2 className="text-center text-dark">
                  YTD : <NumberFormat value={this.state.statistics.jobCardYTDCosts?.ytdCost.toFixed(0)} thousandSeparator={true} displayType="text" />
                </h2>
                <h4 className="text-center text-muted">
                  Last Year : <NumberFormat value={this.state.statistics.jobCardYTDCosts?.lastYTDCost.toFixed(0)} thousandSeparator={true} displayType="text" />
                </h4>
              </Card.Body>
            </div>
          </Card>
        </Col>
        <Col lg={3}>
          <Card style={{ width: "100%", height: "100%", textAlign: "center" }}>
            <Row>
              <Col style={{ paddingTop: "5px" }}>
                <Button
                  style={{ width: "fit-content" }}
                  variant={"success"}
                  size="sm"
                  title="Download Chart"
                  onClick={() =>
                    this.saveChartToPng(
                      "mainGauge",
                      `${
                        this.props.company.jobCardPrefix !== null && this.props.company.jobCardPrefix !== "" ? this.props.company.jobCardPrefix : "Job Card"
                      }s YTD completed within target time`
                    )
                  }
                >
                  <FontAwesomeIcon icon={faDownload} />
                </Button>
              </Col>
            </Row>{" "}
            <div id="mainGauge" style={{ backgroundColor: "white" }}>
              <Card.Header>
                <Card.Title>
                  {this.props.company.jobCardPrefix !== null && this.props.company.jobCardPrefix !== "" ? this.props.company.jobCardPrefix : "Job Card"}s YTD
                  completed within target time
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <GaugeChart
                  id="gauge-chart2"
                  nrOfLevels={gaugeColours.length}
                  colors={gaugeColours}
                  percent={isNaN(this.state.statistics.mtdCompletedInTarget?.percentage) ? 1 : this.state.statistics.mtdCompletedInTarget?.percentage}
                  arcPadding={0.02}
                  textColor="#000"
                  needleColor="#bbb"
                  needleBaseColor="#bbb"
                  cornerRadius={2}
                />
              </Card.Body>
            </div>
          </Card>
        </Col>
        <Col lg={3}>
          <Card style={{ width: "100%", height: "100%", textAlign: "center" }}>
            <Row>
              <Col style={{ paddingTop: "5px" }}>
                <Button
                  style={{ width: "fit-content" }}
                  variant={"success"}
                  size="sm"
                  title="Download Chart"
                  onClick={() =>
                    this.saveChartToPng(
                      "mainCategory",
                      `${
                        this.props.company.jobCardPrefix !== null && this.props.company.jobCardPrefix !== "" ? this.props.company.jobCardPrefix : "Job Card"
                      }s YTD by Category`
                    )
                  }
                >
                  <FontAwesomeIcon icon={faDownload} />
                </Button>
              </Col>
            </Row>
            <div id="mainCategory" style={{ backgroundColor: "white" }}>
              <Card.Header>
                <Card.Title>
                  {this.props.company.jobCardPrefix !== null && this.props.company.jobCardPrefix !== "" ? this.props.company.jobCardPrefix : "Job Card"}s YTD by
                  Category
                </Card.Title>
              </Card.Header>
              <Card.Body style={{ padding: 2 }}>
                <Bar height={200} data={data} options={barOptions} />
              </Card.Body>{" "}
            </div>
          </Card>
        </Col>

        <Col lg={3}>
          <Card style={{ width: "100%", height: "100%", textAlign: "center" }}>
            <Row>
              <Col style={{ paddingTop: "5px" }}>
                <Button
                  style={{ width: "fit-content" }}
                  variant={"success"}
                  size="sm"
                  title="Download Chart"
                  onClick={() =>
                    this.saveChartToPng(
                      "mainDepartment",
                      `${
                        this.props.company.jobCardPrefix !== null && this.props.company.jobCardPrefix !== "" ? this.props.company.jobCardPrefix : "Job Card"
                      }s YTD by Department`
                    )
                  }
                >
                  <FontAwesomeIcon icon={faDownload} />
                </Button>
              </Col>
            </Row>
            <div id="mainDepartment" style={{ backgroundColor: "white" }}>
              <Card.Header>
                <Card.Title>
                  {this.props.company.jobCardPrefix !== null && this.props.company.jobCardPrefix !== "" ? this.props.company.jobCardPrefix : "Job Card"}s YTD by
                  Department
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Bar height={200} data={dataPerOperationalUnit} options={barOptions} />
              </Card.Body>
            </div>
          </Card>
        </Col>
      </Row>
    );
  }
}

export interface IJobCardStatsViewModel {
  jobCardPerMaintenanceTypes: IJobCardPerMaintenanceTypeViewModel[];
  jobCardPerOperationalUnits: IJobCardPerOperationalUnitViewModel[];
  jobCardYTDCosts: IJobCardYTDCostsViewModel;
  mtdCompletedInTarget: IJobCardMTDCompletedInTargetViewModel;
}

export interface IJobCardMTDCompletedInTargetViewModel {
  percentage: number;
}

export interface IJobCardYTDCostsViewModel {
  lastMTDCost: number;
  lastYTDCost: number;
  mtdCost: number;
  ytdCost: number;
}

export interface IJobCardPerMaintenanceTypeViewModel {
  count: number;
  maintenanceTypeName: string;
}

export interface IJobCardPerOperationalUnitViewModel {
  count: number;
  operationalUnitName: string;
}

export function createJobCardStatsViewModel(): IJobCardStatsViewModel {
  return {
    jobCardPerMaintenanceTypes: [],
    jobCardPerOperationalUnits: [],
    jobCardYTDCosts: createJobCardYTDCostsViewModel(),
    mtdCompletedInTarget: createJobCardMTDCompletedInTargetViewModel(),
  };
}

export function createJobCardMTDCompletedInTargetViewModel(): IJobCardMTDCompletedInTargetViewModel {
  return {
    percentage: 0,
  };
}

export function createJobCardYTDCostsViewModel(): IJobCardYTDCostsViewModel {
  return {
    lastMTDCost: 0,
    lastYTDCost: 0,
    mtdCost: 0,
    ytdCost: 0,
  };
}
